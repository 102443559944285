import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchLoginUser(action) {
	try {
		const result = yield fromApi.loginUser(action.payload).then((data) => {
			return data;
		});
		yield put({type: 'LOGIN_USER_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* loginUser() {
	yield takeLatest('LOGIN_USER_SAGAS', watchLoginUser);
}

export default function* rootSaga() {
	yield all([loginUser()]);
}
