export const GET_COEURS_RETURN = 'GET_COEURS_RETURN';
export const GET_COEURS_SAGAS = 'GET_COEURS_SAGAS';

export const POST_COEUR_RETURN = 'POST_COEUR_RETURN';
export const POST_COEUR_SAGAS = 'POST_COEUR_SAGAS';

export const GET_COEUR_RETURN = 'GET_COEUR_RETURN';
export const GET_COEUR_SAGAS = 'GET_COEUR_SAGAS';

export const PUT_COEUR_RETURN = 'PUT_COEUR_RETURN';
export const PUT_COEUR_SAGAS = 'PUT_COEUR_SAGAS';

export const PUT_COEURSTATUT_RETURN = 'PUT_COEURSTATUT_RETURN';
export const PUT_COEURSTATUT_SAGAS = 'PUT_COEURSTATUT_SAGAS';

export const DEL_COEUR_RETURN = 'DEL_COEUR_RETURN';
export const DEL_COEUR_SAGAS = 'DEL_COEUR_SAGAS';

//* GET COEUR ALL
export const getCoeursSagas = (xAccess) => ({
	type: GET_COEURS_SAGAS,
	token: xAccess
});
export const getCoeursReturn = (result) => ({
	type: GET_COEURS_RETURN,
	payload: result
});

//* POST COEUR
export const postCoeurSagas = (xAccess, data) => ({
	type: POST_COEUR_SAGAS,
	token: xAccess,
	data
});
export const postCoeurReturn = (result) => ({
	type: POST_COEUR_RETURN,
	payload: result
});

//* GET COEUR ONE
export const getCoeurSagas = (uuid, xAccess) => ({
	type: GET_COEUR_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getCoeurReturn = (result) => ({
	type: GET_COEUR_RETURN,
	payload: result
});

//* PUT COEUR
export const putCoeurSagas = (uuid, xAccess, data) => ({
	type: PUT_COEUR_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putCoeurReturn = (result) => ({
	type: PUT_COEUR_RETURN,
	payload: result
});

//* PUT COEUR STATUT
export const putCoeurStatutSagas = (uuid, xAccess, data) => ({
	type: PUT_COEURSTATUT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putCoeurStatutReturn = (result) => ({
	type: PUT_COEURSTATUT_RETURN,
	payload: result
});

export const deleteCoeurSagas = (uuid, xAccess) => ({
	type: DEL_COEUR_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteCoeurReturn = (result) => ({
	type: DEL_COEUR_RETURN,
	payload: result
});
