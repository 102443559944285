import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetEmploiscats(action) {
	try {
		const result = yield fromApi.emploiscatGets().then((data) => {
			return data;
		});
		yield put({type: 'GET_EMPLOICATS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutEmploicat(action) {
	try {
		const result = yield fromApi.emploicatPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_EMPLOICAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPostEmploicat(action) {
	try {
		const result = yield fromApi.emploicatPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_EMPLOICAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelEmploicat(action) {
	try {
		const result = yield fromApi.emploicatDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_EMPLOICAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* emploiscat() {
	yield takeLatest('GET_EMPLOICATS_SAGAS', watchGetEmploiscats);
	yield takeLatest('PUT_EMPLOICAT_SAGAS', watchPutEmploicat);
	yield takeLatest('POST_EMPLOICAT_SAGAS', watchPostEmploicat);
	yield takeLatest('DEL_EMPLOICAT_SAGAS', watchDelEmploicat);
}

export default function* rootSaga() {
	yield all([emploiscat()]);
}
