import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostTicketreponse(action) {
	try {
		const result = yield fromApi.ticketreponsePost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_REPONSE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTickets(action) {
	try {
		const result = yield fromApi.ticketsGets(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TICKETS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTicketReponse(action) {
	try {
		const result = yield fromApi.ticketGetByReponse(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TICKETREPONSE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelTicket(action) {
	try {
		const result = yield fromApi.ticketDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_TICKET_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* tickets() {
	yield takeLatest('POST_REPONSE_SAGAS', watchPostTicketreponse);
	yield takeLatest('GET_TICKETS_SAGAS', watchGetTickets);
	yield takeLatest('GET_TICKETREPONSE_SAGAS', watchGetTicketReponse);
	yield takeLatest('DEL_TICKET_SAGAS', watchDelTicket);
}

export default function* rootSaga() {
	yield all([tickets()]);
}
