export const POST_TESTEURS_RETURN = 'POST_TESTEURS_RETURN';
export const POST_TESTEURS_SAGAS = 'POST_TESTEURS_SAGAS';

export const GET_TESTEURS_RETURN = 'GET_TESTEURS_RETURN';
export const GET_TESTEURS_SAGAS = 'GET_TESTEURS_SAGAS';

export const GET_TESTEUR_RETURN = 'GET_TESTEUR_RETURN';
export const GET_TESTEUR_SAGAS = 'GET_TESTEUR_SAGAS';

export const PUT_TESTEUR_RETURN = 'PUT_TESTEUR_RETURN';
export const PUT_TESTEUR_SAGAS = 'PUT_TESTEUR_SAGAS';

export const DEL_TESTEUR_RETURN = 'DEL_TESTEUR_RETURN';
export const DEL_TESTEUR_SAGAS = 'DEL_TESTEUR_SAGAS';

//* POST BANCS TESTEUR
export const postTesteurSagas = (xAccess, data) => ({
	type: POST_TESTEURS_SAGAS,
	token: xAccess,
	data: data
});
export const postTesteurReturn = (result) => ({
	type: POST_TESTEURS_RETURN,
	payload: result
});

//* GET ALL BANCS TESTEURS
export const getTesteursSagas = (xAccess) => ({
	type: GET_TESTEURS_SAGAS,
	token: xAccess
});
export const getTesteursReturn = (result) => ({
	type: GET_TESTEURS_RETURN,
	payload: result
});

//* GET ONE BANCS TESTEUR
export const getTesteurSagas = (uuid, xAccess) => ({
	type: GET_TESTEUR_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getTesteurReturn = (result) => ({
	type: GET_TESTEUR_RETURN,
	payload: result
});

//* PUT BANCS TESTEUR
export const putTesteurSagas = (uuid, xAccess, data) => ({
	type: PUT_TESTEUR_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putTesteurReturn = (result) => ({
	type: PUT_TESTEUR_RETURN,
	payload: result
});

//* DELETE BANCS TESTEUR
export const deleteTesteurSagas = (uuid, xAccess) => ({
	type: DEL_TESTEUR_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteTesteurReturn = (result) => ({
	type: DEL_TESTEUR_RETURN,
	payload: result
});
