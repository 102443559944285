import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostTremplin(action) {
	try {
		const result = yield fromApi.tremplinPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_TREMPLIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTremplins(action) {
	try {
		const result = yield fromApi.tremplinsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TREMPLINS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTremplin(action) {
	try {
		const result = yield fromApi.tremplinGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TREMPLIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutTremplin(action) {
	try {
		const result = yield fromApi.tremplinPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_TREMPLIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchClotureTremplin(action) {
	try {
		const result = yield fromApi.tremplinCloture(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'CLOTURE_TREMPLIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelTremplin(action) {
	try {
		const result = yield fromApi.tremplinDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_TREMPLIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* tremplins() {
	yield takeLatest('POST_TREMPLIN_SAGAS', watchPostTremplin);
	yield takeLatest('GET_TREMPLINS_SAGAS', watchGetTremplins);
	yield takeLatest('GET_TREMPLIN_SAGAS', watchGetTremplin);
	yield takeLatest('PUT_TREMPLIN_SAGAS', watchPutTremplin);
	yield takeLatest('CLOTURE_TREMPLIN_SAGAS', watchClotureTremplin);
	yield takeLatest('DEL_TREMPLIN_SAGAS', watchDelTremplin);
}

export default function* rootSaga() {
	yield all([tremplins()]);
}
