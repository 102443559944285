import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostConcertscat(action) {
	try {
		const result = yield fromApi.concertscatPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_CONCERTSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetConcertscat(action) {
	try {
		const result = yield fromApi.concertscatGets(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_CONCERTSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutConcertscat(action) {
	try {
		const result = yield fromApi.concertscatPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_CONCERTSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* concertscat() {
	yield takeLatest('POST_CONCERTSCAT_SAGAS', watchPostConcertscat);
	yield takeLatest('GET_CONCERTSCAT_SAGAS', watchGetConcertscat);
	yield takeLatest('PUT_CONCERTSCAT_SAGAS', watchPutConcertscat);
}

export default function* rootSaga() {
	yield all([concertscat()]);
}
