import {toast} from 'react-toastify';

let initialState = {
	loader: false,
	infos: [],
	tremplins: [],
	one: [],
	create: [],
	update: []
};

const getTremplinscatInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_TREMPLINSCATS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'GET_TREMPLINCAT_SAGAS':
			return Object.assign({}, state, {loader: true});

		case 'GET_TREMPLINCAT_RETURN':
			return Object.assign({}, state, {
				one: action.payload,
				loader: false
			});

		case 'GET_CAT_TREMPLINS_SAGAS':
			return Object.assign({}, state, {loader: true});

		case 'GET_CAT_TREMPLINS_RETURN':
			return Object.assign({}, state, {
				tremplins: action.payload,
				loader: false
			});

		case 'POST_TREMPLINSCATS_RETURN':
			const arrayCat = [action.payload.data];
			let newStateAdd;
			if (action.payload.status === 201) {
				newStateAdd = {...state.infos, data: state.infos.data.concat(arrayCat)};
				const notifySuccess = () =>
					toast.success('Catégorie ajoutée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateAdd = state.infos;
				if (action.payload.status === 400) {
					const notifyError = () =>
						toast.error('Email déjà enregistré !', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						});
					notifyError();
				}
			}
			return Object.assign({}, state, {
				create: action.payload,
				infos: newStateAdd
			});

		case 'PUT_TREMPLINCAT_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateUpdate = {...state.infos, data: array};
				const notifySuccess = () =>
					toast.success('Catégorie mise à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdate,
				one: action.payload
			});

		case 'PUT_TREMPLINCATORDRE_RETURN':
			let newOrderUpdate;
			if (action.payload.status === 200) {
				state.infos.data.map((item) => {
					return action.payload.data;
				});
				newOrderUpdate = {...state.infos, data: action.payload.data};
				/*const notifySuccess = () => toast.success("Ordre mise à jour !", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                notifySuccess()*/
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newOrderUpdate
			});

		case 'DEL_TREMPLINCAT_SAGAS':
			const tremplincat = state.infos.data;
			const indexTremplincat = tremplincat.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.infos, data: tremplincat.filter((item, i) => i !== indexTremplincat)};

			return Object.assign({}, state, {infos: newStateDelete});
		case 'DEL_TREMPLINCAT_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Catégorie supprimée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getTremplinscatInfos;
