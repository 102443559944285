import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Layout} from './utility/context/Layout';
import {store} from './redux/storeConfig/store';
import 'antd/dist/antd.css';
import './index.scss';
import {saveState} from './redux/storeConfig/localStorage';
import App from './App';

store.subscribe(() => {
	saveState(store.getState());
});
ReactDOM.render(
	<Provider store={store}>
		<Layout>
			<App />
		</Layout>
	</Provider>,
	document.getElementById('root')
);
