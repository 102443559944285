import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostBancs(action) {
	try {
		const result = yield fromApi.bancPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_BANCS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetBancs(action) {
	try {
		const result = yield fromApi.bancsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BANCS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetBanc(action) {
	try {
		const result = yield fromApi.bancGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BANC_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutBanc(action) {
	try {
		const result = yield fromApi.bancPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_BANC_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelBanc(action) {
	try {
		const result = yield fromApi.bancDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_BANC_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* bancs() {
	yield takeLatest('POST_BANCS_SAGAS', watchPostBancs);
	yield takeLatest('GET_BANCS_SAGAS', watchGetBancs);
	yield takeLatest('GET_BANC_SAGAS', watchGetBanc);
	yield takeLatest('PUT_BANC_SAGAS', watchPutBanc);
	yield takeLatest('DEL_BANC_SAGAS', watchDelBanc);
}

export default function* rootSaga() {
	yield all([bancs()]);
}
