import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: []
};

const getConcertsInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_CONCERTS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'GET_CONCERT_RETURN':
			return Object.assign({}, state, {one: action.payload});

		case 'PUT_CONCERT_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Annonce mise à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				one: action.payload
			});
		case 'DEL_CONCERT_SAGAS':
			const pas = state.infos.data.items;
			const indexPa = pas.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.infos, data: pas.filter((item, i) => i !== indexPa)};

			return Object.assign({}, state, {infos: newStateDelete});
		case 'DEL_CONCERT_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Annonce supprimé !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getConcertsInfos;
