export const POST_MARQUE_RETURN = 'POST_MARQUE_RETURN';
export const POST_MARQUE_SAGAS = 'POST_MARQUE_SAGAS';

export const GET_MARQUES_RETURN = 'GET_MARQUES_RETURN';
export const GET_MARQUES_SAGAS = 'GET_MARQUES_SAGAS';

export const PUT_MARQUE_RETURN = 'PUT_MARQUE_RETURN';
export const PUT_MARQUE_SAGAS = 'PUT_MARQUE_SAGAS';

export const DEL_MARQUE_RETURN = 'DEL_MARQUE_RETURN';
export const DEL_MARQUE_SAGAS = 'DEL_MARQUE_SAGAS';

/********** MARQUES POST ****************/
export const postMarqueSagas = (xAccess, data) => ({
	type: POST_MARQUE_SAGAS,
	token: xAccess,
	data: data
});
export const postMarqueReturn = (result) => ({
	type: POST_MARQUE_RETURN,
	payload: result
});

/********** MARQUES GET ALL ****************/
export const getMarquesSagas = (xAccess) => ({
	type: GET_MARQUES_SAGAS,
	token: xAccess
});
export const getMarquesReturn = (result) => ({
	type: GET_MARQUES_RETURN,
	payload: result
});

/********** MARQUES PUT ****************/
export const putMarqueSagas = (uuid, xAccess, data) => ({
	type: PUT_MARQUE_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putMarqueReturn = (result) => ({
	type: PUT_MARQUE_RETURN,
	payload: result
});

/********** MARQUES DELETE ****************/
export const deleteMarqueSagas = (uuid, xAccess) => ({
	type: DEL_MARQUE_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteMarqueReturn = (result) => ({
	type: DEL_MARQUE_RETURN,
	payload: result
});
