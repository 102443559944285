export const GET_EMPLOIS_RETURN = 'GET_EMPLOIS_RETURN';
export const GET_EMPLOIS_SAGAS = 'GET_EMPLOIS_SAGAS';

export const GET_EMPLOI_RETURN = 'GET_EMPLOI_RETURN';
export const GET_EMPLOI_SAGAS = 'GET_EMPLOI_SAGAS';

export const PUT_EMPLOI_RETURN = 'PUT_EMPLOI_RETURN';
export const PUT_EMPLOI_SAGAS = 'PUT_EMPLOI_SAGAS';

export const DEL_EMPLOI_RETURN = 'DEL_EMPLOI_RETURN';
export const DEL_EMPLOI_SAGAS = 'DEL_EMPLOI_SAGAS';
//* POST EMPLOI

//* GET ALL EMPLOIS
export const getEmploisSagas = (page, range, cat, dep) => ({
	type: GET_EMPLOIS_SAGAS,
	page,
	range,
	cat,
	dep
});
export const getEmploisReturn = (result) => ({
	type: GET_EMPLOIS_RETURN,
	payload: result
});

//* GET ONE EMPLOI
export const getEmploiSagas = (uuid) => ({
	type: GET_EMPLOI_SAGAS,
	payload: uuid
});
export const getEmploiReturn = (result) => ({
	type: GET_EMPLOI_RETURN,
	payload: result
});

//* UPDATE EMPLOI
export const putEmploiSagas = (uuid, data) => ({
	type: PUT_EMPLOI_SAGAS,
	payload: uuid,
	data: data
});
export const putEmploiReturn = (result) => ({
	type: PUT_EMPLOI_RETURN,
	payload: result
});

//* DELETE EMPLOI
export const deleteEmploiSagas = (uuid, xAccess) => ({
	type: DEL_EMPLOI_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteEmploiReturn = (result) => ({
	type: DEL_EMPLOI_RETURN,
	payload: result
});
