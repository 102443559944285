export const POST_PARRAIN_RETURN = 'POST_PARRAIN_RETURN';
export const POST_PARRAIN_SAGAS = 'POST_PARRAIN_SAGAS';

export const GET_PARRAINS_RETURN = 'GET_PARRAINS_RETURN';
export const GET_PARRAINS_SAGAS = 'GET_PARRAINS_SAGAS';

export const GET_PARRAIN_RETURN = 'GET_PARRAIN_RETURN';
export const GET_PARRAIN_SAGAS = 'GET_PARRAIN_SAGAS';

export const PUT_PARRAIN_RETURN = 'PUT_PARRAIN_RETURN';
export const PUT_PARRAIN_SAGAS = 'PUT_PARRAIN_SAGAS';

export const DEL_PARRAIN_RETURN = 'DEL_PARRAIN_RETURN';
export const DEL_PARRAIN_SAGAS = 'DEL_PARRAIN_SAGAS';

// POST PARRAIN
export const postParrainSagas = (xAccess, data) => ({
	type: POST_PARRAIN_SAGAS,
	token: xAccess,
	data: data
});
export const postParrainReturn = (result) => ({
	type: POST_PARRAIN_RETURN,
	payload: result
});

// GET PARRAINS
export const getParrainsSagas = (xAccess) => ({
	type: GET_PARRAINS_SAGAS,
	token: xAccess
});
export const getParrainsReturn = (result) => ({
	type: GET_PARRAINS_RETURN,
	payload: result
});

// GET PARRAIN
export const getParrainSagas = (uuid, xAccess) => ({
	type: GET_PARRAIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getParrainReturn = (result) => ({
	type: GET_PARRAIN_RETURN,
	payload: result
});

// PUT PARRAIN
export const putParrainSagas = (uuid, xAccess, data) => ({
	type: PUT_PARRAIN_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putParrainReturn = (result) => ({
	type: PUT_PARRAIN_RETURN,
	payload: result
});

// DELETE PARRAIN
export const deleteParrainSagas = (uuid, xAccess) => ({
	type: DEL_PARRAIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteParrainReturn = (result) => ({
	type: DEL_PARRAIN_RETURN,
	payload: result
});
