import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostLaureat(action) {
	try {
		const result = yield fromApi.laureatPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_LAUREAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetLaureatsTremplin(action) {
	try {
		const result = yield fromApi.laureatsTremplinGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_LAUREATSTREMPLIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelLaureat(action) {
	try {
		const result = yield fromApi.laureatDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_LAUREAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* tremplinslaureat() {
	yield takeLatest('POST_LAUREAT_SAGAS', watchPostLaureat);
	yield takeLatest('GET_LAUREATSTREMPLIN_SAGAS', watchGetLaureatsTremplin);
	yield takeLatest('DEL_LAUREAT_SAGAS', watchDelLaureat);
}

export default function* rootSaga() {
	yield all([tremplinslaureat()]);
}
