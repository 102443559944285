import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getHomeInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_CONTENT_RETURN':
			return Object.assign({}, state, {one: action.payload});

		case 'PUT_CONTENT_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Message mis à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				one: action.payload
			});

		default:
			return state;
	}
};

export default getHomeInfos;
