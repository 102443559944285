import {createStore, applyMiddleware, compose} from 'redux';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'react-router-redux';
import {loadState} from './localStorage';
import rootSaga from '../sagas';

const persistedStore = loadState();
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, persistedStore, composeEnhancers(applyMiddleware(sagaMiddleware, ...middlewares, routerMiddleware(history))));
sagaMiddleware.run(rootSaga);
