export const GET_PACATS_RETURN = 'GET_PACATS_RETURN';
export const GET_PACATS_SAGAS = 'GET_PACATS_SAGAS';

export const PUT_PACAT_RETURN = 'PUT_PACAT_RETURN';
export const PUT_PACAT_SAGAS = 'PUT_PACAT_SAGAS';

export const POST_PACAT_RETURN = 'POST_PACAT_RETURN';
export const POST_PACAT_SAGAS = 'POST_PACAT_SAGAS';

export const DEL_PACAT_RETURN = 'DEL_PACAT_RETURN';
export const DEL_PACAT_SAGAS = 'DEL_PACAT_SAGAS';

//* GET PA CAT BY TYPE
export const getpacatsSagas = (xAccess) => ({
	type: GET_PACATS_SAGAS,
	token: xAccess
});
export const getpacatsReturn = (result) => ({
	type: GET_PACATS_RETURN,
	payload: result
});

//* PUT PA CAT
export const putPacatSagas = (uuid, xAccess, data) => ({
	type: PUT_PACAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putPacatReturn = (result) => ({
	type: PUT_PACAT_RETURN,
	payload: result
});

//* POST PA CAT
export const postPacatSagas = (xAccess, data) => ({
	type: POST_PACAT_SAGAS,
	token: xAccess,
	data: data
});
export const postPacatReturn = (result) => ({
	type: POST_PACAT_RETURN,
	payload: result
});

//* DELETE PA CAT
export const deletePacatSagas = (uuid, xAccess) => ({
	type: DEL_PACAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deletePacatReturn = (result) => ({
	type: DEL_PACAT_RETURN,
	payload: result
});
