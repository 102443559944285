export const POST_BANCS_RETURN = 'POST_BANCS_RETURN';
export const POST_BANCS_SAGAS = 'POST_BANCS_SAGAS';

export const GET_BANCS_RETURN = 'GET_BANCS_RETURN';
export const GET_BANCS_SAGAS = 'GET_BANCS_SAGAS';

export const GET_BANC_RETURN = 'GET_BANC_RETURN';
export const GET_BANC_SAGAS = 'GET_BANC_SAGAS';

export const PUT_BANC_RETURN = 'PUT_BANC_RETURN';
export const PUT_BANC_SAGAS = 'PUT_BANC_SAGAS';

export const DEL_BANC_RETURN = 'DEL_BANC_RETURN';
export const DEL_BANC_SAGAS = 'DEL_BANC_SAGAS';

//* POST BANCS
export const postBancsSagas = (xAccess, data) => ({
	type: POST_BANCS_SAGAS,
	token: xAccess,
	data: data
});
export const postBancsReturn = (result) => ({
	type: POST_BANCS_RETURN,
	payload: result
});

//* GET ALL BANCS
export const getBancsSagas = (xAccess) => ({
	type: GET_BANCS_SAGAS,
	token: xAccess
});
export const getBancsReturn = (result) => ({
	type: GET_BANCS_RETURN,
	payload: result
});

//* GET ONE BANCS
export const getBancSagas = (uuid, xAccess) => ({
	type: GET_BANC_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getBancReturn = (result) => ({
	type: GET_BANC_RETURN,
	payload: result
});

//* PUT BANCS
export const putBancSagas = (uuid, xAccess, data) => ({
	type: PUT_BANC_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putBancReturn = (result) => ({
	type: PUT_BANC_RETURN,
	payload: result
});

//* DELETE BANCS
export const deleteBancsSagas = (uuid, xAccess) => ({
	type: DEL_BANC_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteBancsReturn = (result) => ({
	type: DEL_BANC_RETURN,
	payload: result
});
