export const POST_TYPE_RETURN = 'POST_TYPE_RETURN';
export const POST_TYPE_SAGAS = 'POST_TYPE_SAGAS';

export const GET_TYPES_RETURN = 'GET_TYPES_RETURN';
export const GET_TYPES_SAGAS = 'GET_TYPES_SAGAS';

export const PUT_TYPES_RETURN = 'PUT_TYPES_RETURN';
export const PUT_TYPES_SAGAS = 'PUT_TYPES_SAGAS';

export const DEL_TYPES_RETURN = 'DEL_TYPES_RETURN';
export const DEL_TYPES_SAGAS = 'DEL_TYPES_SAGAS';

//* POST TICKETS TYPE
export const postTypesSagas = (xAccess, data) => ({
	type: POST_TYPE_SAGAS,
	token: xAccess,
	data: data
});
export const postBTypesReturn = (result) => ({
	type: POST_TYPE_RETURN,
	payload: result
});

//* GET ALL TICKETS TYPES
export const getTypesSagas = (xAccess) => ({
	type: GET_TYPES_SAGAS,
	token: xAccess
});
export const getTypesReturn = (result) => ({
	type: GET_TYPES_RETURN,
	payload: result
});

//* GET ONE TICKETS TYPES

//* PUT TICKETS TYPES
export const putTypesSagas = (uuid, xAccess, data) => ({
	type: PUT_TYPES_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putTypesReturn = (result) => ({
	type: PUT_TYPES_RETURN,
	payload: result
});

//* DELETE TICKETS TYPES
export const deleteTypesSagas = (uuid, xAccess) => ({
	type: DEL_TYPES_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteTypesReturn = (result) => ({
	type: DEL_TYPES_RETURN,
	payload: result
});
