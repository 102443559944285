export const POST_PROCAT_RETURN = 'POST_PROCAT_RETURN';
export const POST_PROCAT_SAGAS = 'POST_PROCAT_SAGAS';

export const GET_PROCATS_RETURN = 'GET_PROCATS_RETURN';
export const GET_PROCATS_SAGAS = 'GET_PROCATS_SAGAS';

export const PUT_PROCAT_RETURN = 'PUT_PROCAT_RETURN';
export const PUT_PROCAT_SAGAS = 'PUT_PROCAT_SAGAS';

export const DEL_PROCAT_RETURN = 'DEL_PROCAT_RETURN';
export const DEL_PROCAT_SAGAS = 'DEL_PROCAT_SAGAS';

/********** PRO CAT POST ****************/
export const postProcatSagas = (xAccess, data) => ({
	type: POST_PROCAT_SAGAS,
	token: xAccess,
	data: data
});
export const postProcatReturn = (result) => ({
	type: POST_PROCAT_RETURN,
	payload: result
});

/********** PRO CAT GET ALL ****************/
export const getProcatsSagas = (xAccess) => ({
	type: GET_PROCATS_SAGAS,
	token: xAccess
});
export const getProcatsReturn = (result) => ({
	type: GET_PROCATS_RETURN,
	payload: result
});

/********** PRO CAT GET ONE ****************/

/********** PRO CAT PUT ****************/
export const putProcatSagas = (uuid, xAccess, data) => ({
	type: PUT_PROCAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putProcatReturn = (result) => ({
	type: PUT_PROCAT_RETURN,
	payload: result
});

/********** PRO CAT DELETE ****************/
export const deleteProcatSagas = (uuid, xAccess) => ({
	type: DEL_PROCAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteProcatReturn = (result) => ({
	type: DEL_PROCAT_RETURN,
	payload: result
});
