export const POST_LAUREAT_RETURN = 'POST_LAUREAT_RETURN';
export const POST_LAUREAT_SAGAS = 'POST_LAUREAT_SAGAS';

export const GET_LAUREATSTREMPLIN_RETURN = 'GET_LAUREATSTREMPLIN_RETURN';
export const GET_LAUREATSTREMPLIN_SAGAS = 'GET_LAUREATSTREMPLIN_SAGAS';

export const DEL_LAUREAT_RETURN = 'DEL_LAUREAT_RETURN';
export const DEL_LAUREAT_SAGAS = 'DEL_LAUREAT_SAGAS';

//* POST LAUREAT
export const postLaureatSagas = (xAccess, data) => ({
	type: POST_LAUREAT_SAGAS,
	token: xAccess,
	data: data
});
export const postLaureatReturn = (result) => ({
	type: POST_LAUREAT_RETURN,
	payload: result
});

//* GET LAUREATS BY TREMPLIN
export const getLaureatsTremplinSagas = (uuid, xAccess) => ({
	type: GET_LAUREATSTREMPLIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getLaureatsTremplinReturn = (result) => ({
	type: GET_LAUREATSTREMPLIN_RETURN,
	payload: result
});

//* GET ONE LAUREAT

//* PUT LAUREAT

// DELETE LAUREAT
export const deleteLaureatSagas = (uuid, xAccess) => ({
	type: DEL_LAUREAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteLaureatReturn = (result) => ({
	type: DEL_LAUREAT_RETURN,
	payload: result
});
