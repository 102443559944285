import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostNewsletter(action) {
	try {
		const result = yield fromApi.newsletterPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_NEWSLETTER_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetNewsletters(action) {
	try {
		const result = yield fromApi.newslettersGets(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_NEWSLETTERS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetNewsletter(action) {
	try {
		const result = yield fromApi.newsletterGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_NEWSLETTER_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutNewsletter(action) {
	try {
		const result = yield fromApi.newsletterPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_NEWSLETTER_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPostNewsExpe(action) {
	try {
		const result = yield fromApi.newsletterExpePost(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_NEWSEXPE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPostNewsExpeGroup(action) {
	try {
		const result = yield fromApi.newsletterExpeGroupPost(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_NEWSEXPEGROUP_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelNewsletter(action) {
	try {
		const result = yield fromApi.newsletterDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_NEWSLETTER_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* newsletters() {
	yield takeLatest('POST_NEWSLETTER_SAGAS', watchPostNewsletter);
	yield takeLatest('GET_NEWSLETTERS_SAGAS', watchGetNewsletters);
	yield takeLatest('GET_NEWSLETTER_SAGAS', watchGetNewsletter);
	yield takeLatest('PUT_NEWSLETTER_SAGAS', watchPutNewsletter);
	yield takeLatest('POST_NEWSEXPE_SAGAS', watchPostNewsExpe);
	yield takeLatest('POST_NEWSEXPEGROUP_SAGAS', watchPostNewsExpeGroup);
	yield takeLatest('DEL_NEWSLETTER_SAGAS', watchDelNewsletter);
}

export default function* rootSaga() {
	yield all([newsletters()]);
}
