import React from 'react';
import Router from './Router';
import './components/@vuexy/rippleButton/RippleButton';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/themes/prism-tomorrow.css';

// import {fal} from '@fortawesome/pro-light-svg-icons'
// import {far} from '@fortawesome/pro-regular-svg-icons'
// import {fad} from '@fortawesome/pro-duotone-svg-icons'
// import {faQuestionCircle,faHome,faLockAlt,faBullhorn,faMicrophoneAlt,faComment,faUsers,faCheck,faFlag,faTimes,faInfoCircle,faUserPlus,faSyncAlt} from '@fortawesome/pro-solid-svg-icons'
// import { library } from '@fortawesome/fontawesome-svg-core'

import {ConfigProvider} from 'antd';
import frFR from 'antd/lib/locale/fr_FR';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

// library.add (fal,far,fad,faQuestionCircle,faHome,faLockAlt,faBullhorn,faMicrophoneAlt,faComment,faUsers,faCheck,faFlag,faTimes,faInfoCircle,faUserPlus,faSyncAlt)

const App = (props) => {
	return (
		<ConfigProvider locale={frFR}>
			<Router />
		</ConfigProvider>
	);
};

export default App;
