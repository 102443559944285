export const GET_PAS_RETURN = 'GET_PAS_RETURN';
export const GET_PAS_SAGAS = 'GET_PAS_SAGAS';

export const PUT_PA_RETURN = 'PUT_PA_RETURN';
export const PUT_PA_SAGAS = 'PUT_PA_SAGAS';

export const DEL_PA_RETURN = 'DEL_PA_RETURN';
export const DEL_PA_SAGAS = 'DEL_PA_SAGAS';

//* GET PAS BY TYPE
export const getpasSagas = (type, xAccess) => ({
	type: GET_PAS_SAGAS,
	token: xAccess,
	payload: type
});
export const getpasReturn = (result) => ({
	type: GET_PAS_RETURN,
	payload: result
});

//* UPDATE PA
export const putPaSagas = (uuid, data) => ({
	type: PUT_PA_SAGAS,
	payload: uuid,
	data: data
});
export const putPaReturn = (result) => ({
	type: PUT_PA_RETURN,
	payload: result
});

export const deletePaSagas = (uuid, xAccess) => ({
	type: DEL_PA_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deletePaReturn = (result) => ({
	type: DEL_PA_RETURN,
	payload: result
});
