import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetPleins(action) {
	try {
		const result = yield fromApi.pleinfeuxGets(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_PLEINS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPostPleins(action) {
	try {
		const result = yield fromApi.pleinfeuxPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_PLEIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchGetPlein(action) {
	try {
		const result = yield fromApi.pleinfeuxGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_PLEIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPutPlein(action) {
	try {
		const result = yield fromApi.pleinfeuxPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_PLEIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPutPleinStatut(action) {
	try {
		const result = yield fromApi.pleinfeuxStatutPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_PLEINSTATUT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchDelPlein(action) {
	try {
		const result = yield fromApi.pleinfeuxDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_PLEIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* pleins() {
	yield takeLatest('GET_PLEINS_SAGAS', watchGetPleins);
	yield takeLatest('POST_PLEIN_SAGAS', watchPostPleins);
	yield takeLatest('GET_PLEIN_SAGAS', watchGetPlein);
	yield takeLatest('PUT_PLEIN_SAGAS', watchPutPlein);
	yield takeLatest('PUT_PLEINSTATUT_SAGAS', watchPutPleinStatut);
	yield takeLatest('DEL_PLEIN_SAGAS', watchDelPlein);
}

export default function* rootSaga() {
	yield all([pleins()]);
}
