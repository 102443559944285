export const GET_BLOGCATS_RETURN = 'GET_BLOGCATS_RETURN';
export const GET_BLOGCATS_SAGAS = 'GET_BLOGCATS_SAGAS';

export const GET_BLOGCAT_RETURN = 'GET_BLOGCAT_RETURN';
export const GET_BLOGCAT_SAGAS = 'GET_BLOGCAT_SAGAS';

export const POST_BLOGCATS_RETURN = 'POST_BLOGCATS_RETURN';
export const POST_BLOGCATS_SAGAS = 'POST_BLOGCATS_SAGAS';

export const PUT_BLOGCAT_RETURN = 'PUT_BLOGCAT_RETURN';
export const PUT_BLOGCAT_SAGAS = 'PUT_BLOGCAT_SAGAS';

export const DEL_BLOGCAT_RETURN = 'DEL_BLOGCAT_RETURN';
export const DEL_BLOGCAT_SAGAS = 'DEL_BLOGCAT_SAGAS';

export const getBlogcatsSagas = (xAccess) => ({
	type: GET_BLOGCATS_SAGAS,
	token: xAccess
});
export const getBlogcatsReturn = (result) => ({
	type: GET_BLOGCATS_RETURN,
	payload: result
});

export const getBlogcatSagas = (uuid, xAccess) => ({
	type: GET_BLOGCAT_RETURN,
	token: xAccess,
	payload: uuid
});
export const getBlogcatReturn = (result) => ({
	type: GET_BLOGCAT_SAGAS,
	payload: result
});

export const postBlogcatsSagas = (xAccess, data) => ({
	type: POST_BLOGCATS_SAGAS,
	token: xAccess,
	data: data
});
export const postBlogcatsReturn = (result) => ({
	type: POST_BLOGCATS_RETURN,
	payload: result
});

export const putBlogcatSagas = (uuid, xAccess, data) => ({
	type: PUT_BLOGCAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putBlogcatReturn = (result) => ({
	type: PUT_BLOGCAT_RETURN,
	payload: result
});
export const deleteBlogcatSagas = (uuid, xAccess) => ({
	type: DEL_BLOGCAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteBlogcatReturn = (result) => ({
	type: DEL_BLOGCAT_RETURN,
	payload: result
});
