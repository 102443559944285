export const GET_CONTENT_RETURN = 'GET_CONTENT_RETURN';
export const GET_CONTENT_SAGAS = 'GET_CONTENT_SAGAS';

export const PUT_CONTENT_RETURN = 'PUT_CONTENT_RETURN';
export const PUT_CONTENT_SAGAS = 'PUT_CONTENT_SAGAS';

//* GET MESSAGE HOME
export const getHomeContentSagas = (uuid) => ({
	type: GET_CONTENT_SAGAS,
	payload: uuid
});
export const getHomeContentReturn = (result) => ({
	type: GET_CONTENT_RETURN,
	payload: result
});

//* PUT MESSAGE HOME
export const putHomeContentSagas = (uuid, xAccess, data) => ({
	type: PUT_CONTENT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putHomeContentReturn = (result) => ({
	type: PUT_CONTENT_RETURN,
	payload: result
});
