import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetEspaces(action) {
	try {
		const result = yield fromApi.espacesGet(action.token, action.page, action.range, action.dep).then((data) => {
			return data;
		});
		yield put({type: 'GET_ESPACES_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutESpace(action) {
	try {
		const result = yield fromApi.espacesPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_ESPACE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* espaces() {
	yield takeLatest('GET_ESPACES_SAGAS', watchGetEspaces);
	yield takeLatest('PUT_ESPACE_SAGAS', watchPutESpace);
}

export default function* rootSaga() {
	yield all([espaces()]);
}
