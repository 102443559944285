import axios from 'axios'
import { Configs } from '../../configs/general.config'
const axiosConfig = {
  baseURL: `${Configs.urlApi}/api/${Configs.typeApi}/v1`,
  headers: {
    Authorization: `Bearer ${Configs.BearerToken}`
  }
}
const apiService = {
  get: async (endpoint, xAccess) => {
    let results = []
    try {
      await axios
        .get(endpoint, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'x-access-token': xAccess
          }
        })
        .then((response) => (results = response))
        .catch((error) => (results = error.response))
    } catch (error) {
      results = error.response
      throw error
    }
    return results
  },
  post: async (endpoint, data, xAccess) => {
    let results = []
    try {
      await axios
        .post(endpoint, data, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'x-access-token': xAccess
          }
        })
        .then((response) => (results = response))
        .catch((error) => (results = error.response))
    } catch (error) {
      results = error.response
      throw error
    }
    return results
  },
  put: async (endpoint, data, xAccess) => {
    let results = []
    try {
      await axios
        .put(endpoint, data, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'x-access-token': xAccess
          }
        })
        .then((response) => (results = response))
        .catch((error) => (results = error.response))
    } catch (error) {
      results = error.response
    }
    return results
  },
  delete: async (endpoint, xAccess) => {
    let results = []
    try {
      await axios
        .delete(endpoint, {
          ...axiosConfig,
          headers: {
            ...axiosConfig.headers,
            'x-access-token': xAccess
          }
        })
        .then((response) => (results = response))
        .catch((error) => (results = error.response))
    } catch (error) {
      results = error.response
    }
    return results
  }
}

/* IDENTIFICATION */
export const loginUser = async (data) => {
  return await apiService.post(`/authenticator/admin`, data)
}
export const authUserGet = async (uuid, xAccess) => {
  return await apiService.get(`/admin/${uuid}`, xAccess)
}

/* ADMINISTRATEURS */
export const adminsGet = async (xAccess) => {
  return await apiService.get('/admins', xAccess)
}
export const adminGet = async (uuid, xAccess) => {
  return await apiService.get(`/admin/${uuid}`, xAccess)
}
export const adminPost = async (xAccess, data) => {
  return await apiService.post('/admin', data, xAccess)
}
export const adminPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/admin/${uuid}`, data, xAccess)
}
export const adminDel = async (uuid, xAccess) => {
  return await apiService.delete(`/admin/${uuid}`, xAccess)
}

/* UTILISATEURS */
export const usersGet = async (xAccess, page, range) => {
  return await apiService.get(`/users?page=${page}&size=${range}`, xAccess)
}
export const userPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/user/${uuid}`, data, xAccess)
}

/* BLOG */
export const blogsGet = async (xAccess) => {
  return await apiService.get('/blog/posts', xAccess)
}
export const blogGet = async (uuid, xAccess) => {
  return await apiService.get(`/blog/post/${uuid}`, xAccess)
}
export const blogsPost = async (xAccess, data) => {
  return await apiService.post('/blog/post', data, xAccess)
}
export const blogPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/blog/post/${uuid}`, data, xAccess)
}
export const blogDel = async (uuid, xAccess) => {
  return await apiService.delete(`/blog/post/${uuid}`, xAccess)
}

/* BLOG CAT */
export const blogscatGet = async (xAccess) => {
  return await apiService.get('/blog/cats', xAccess)
}
export const blogcatGet = async (uuid, xAccess) => {
  return await apiService.get(`/blog/cat/${uuid}`, xAccess)
}
export const blogscatPost = async (xAccess, data) => {
  return await apiService.post('/blog/cat', data, xAccess)
}
export const blogcatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/blog/cat/${uuid}`, data, xAccess)
}
export const blogcatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/blog/cat/${uuid}`, xAccess)
}

/* TREMPLINS */
export const tremplinPost = async (xAccess, data) => {
  return await apiService.post('/tremplin', data, xAccess)
}
export const tremplinsGet = async (xAccess) => {
  return await apiService.get('/tremplins', xAccess)
}
export const tremplinsGetStatus = async (status, xAccess) => {
  return await apiService.get(`/tremplins/${status}`, xAccess)
}
export const tremplinGet = async (uuid, xAccess) => {
  return await apiService.get(`/tremplin/${uuid}`, xAccess)
}
export const tremplinPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/tremplin/${uuid}`, data, xAccess)
}
export const tremplinCloture = async (uuid, xAccess, data) => {
  return await apiService.put(`/tremplin/cloture/${uuid}`, data, xAccess)
}
export const tremplinDel = async (uuid, xAccess) => {
  return await apiService.delete(`/tremplin/${uuid}`, xAccess)
}

/* TREMPLINS CAT */
export const tremplinscatGet = async (xAccess) => {
  return await apiService.get('/tremplinscat', xAccess)
}
export const tremplincatGet = async (uuid, xAccess) => {
  return await apiService.get(`/tremplincat/${uuid}`, xAccess)
}
export const tremplincatGetTremplin = async (uuid, xAccess) => {
  return await apiService.get(`/tremplincat/tremplins/${uuid}`, xAccess)
}
export const tremplinscatPost = async (xAccess, data) => {
  return await apiService.post('/tremplinscat', data, xAccess)
}
export const tremplincatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/tremplincat/${uuid}`, data, xAccess)
}
export const tremplincatOrdrePut = async (xAccess, data) => {
  return await apiService.put('/tremplincatordre', data, xAccess)
}
export const tremplincatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/tremplincat/${uuid}`, xAccess)
}

/* TREMPLINS CANDIDATS */
export const candidatsAttenteGet = async (xAccess) => {
  return await apiService.get('/candidats-attente', xAccess)
}
export const candidatGet = async (uuid, xAccess) => {
  return await apiService.get(`/onecandidat/${uuid}`, xAccess)
}
export const candidatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/tremplincandidat/${uuid}`, data, xAccess)
}
export const candidatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/tremplincandidat/${uuid}`, xAccess)
}

/* TREMPLINS LAUREATS */
export const laureatPost = async (xAccess, data) => {
  return await apiService.post('/tremplinslaureat', data, xAccess)
}
export const laureatsTremplinGet = async (uuid, xAccess) => {
  return await apiService.get(`/tremplinslaureats/${uuid}`, xAccess)
}
export const laureatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/tremplinslaureat/${uuid}`, xAccess)
}

/* PARRAINS */
export const parrainPost = async (xAccess, data) => {
  return await apiService.post('/parrain', data, xAccess)
}
export const parrainsGet = async (xAccess) => {
  return await apiService.get('/parrains', xAccess)
}
export const parrainGet = async (uuid, xAccess) => {
  return await apiService.get(`/parrain/${uuid}`, xAccess)
}
export const parrainPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/parrain/${uuid}`, data, xAccess)
}
export const parrainDel = async (uuid, xAccess) => {
  return await apiService.delete(`/parrain/${uuid}`, xAccess)
}

/* BANCS */
export const bancPost = async (xAccess, data) => {
  return await apiService.post('/banc', data, xAccess)
}
export const bancsGet = async (xAccess) => {
  return await apiService.get('/bancs', xAccess)
}
export const bancGet = async (uuid, xAccess) => {
  return await apiService.get(`/banc/${uuid}`, xAccess)
}
export const bancPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/banc/${uuid}`, data, xAccess)
}
export const bancDel = async (uuid, xAccess) => {
  return await apiService.delete(`/banc/${uuid}`, xAccess)
}
// BANCS CAT
export const bancscatPost = async (xAccess, data) => {
  return await apiService.post('/bancs/cat', data, xAccess)
}
export const bancscatsGet = async (xAccess) => {
  return await apiService.get('/bancs/cats', xAccess)
}
export const bancscatGet = async (uuid, xAccess) => {
  return await apiService.get(`/bancs/cat/${uuid}`, xAccess)
}
export const bancscatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/bancs/cat/${uuid}`, data, xAccess)
}
export const bancscatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/bancs/cat/${uuid}`, xAccess)
}
// BANCS TESTEURS
export const bancstesteurPost = async (xAccess, data) => {
  return await apiService.post('/bancs/testeur', data, xAccess)
}
export const bancstesteursGet = async (xAccess) => {
  return await apiService.get('/bancs/testeurs', xAccess)
}
export const bancstesteurGet = async (uuid, xAccess) => {
  return await apiService.get(`/bancs/testeur/${uuid}`, xAccess)
}
export const bancstesteurPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/bancs/testeur/${uuid}`, data, xAccess)
}
export const testeurDel = async (uuid, xAccess) => {
  return await apiService.delete(`/bancs/testeur/${uuid}`, xAccess) // Assuming 'testeurDel' is meant for this type
}
// BANCS REVENDEURS
export const revendeurPost = async (xAccess, data) => {
  return await apiService.post('/revendeur', data, xAccess)
}
export const revendeursGet = async (xAccess, page, range, dep, marque) => {
  // Construct URL with query parameters
  const url = `/revendeurs?page=${page}&size=${range}&dep=${dep}&marque=${marque}`
  return await apiService.get(url, xAccess)
}
export const revendeurPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/revendeur/${uuid}`, data, xAccess)
}

/* MARQUES */
export const marquesPost = async (xAccess, data) => {
  return await apiService.post('/marque', data, xAccess)
}
export const marquesGet = async (xAccess) => {
  return await apiService.get('/marques', xAccess)
}
export const marquePut = async (uuid, xAccess, data) => {
  return await apiService.put(`/marque/${uuid}`, data, xAccess)
}
export const marqueDel = async (uuid, xAccess) => {
  return await apiService.delete(`/marque/${uuid}`, xAccess)
}

/* DEPARTEMENTS */
export const departementPost = async (xAccess, data) => {
  return await apiService.post('/departement', data, xAccess)
}
export const departementsGet = async (xAccess) => {
  return await apiService.get('/departements', xAccess)
}
export const departementPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/departement/${uuid}`, data, xAccess)
}
export const departementDel = async (uuid, xAccess) => {
  return await apiService.delete(`/departement/${uuid}`, xAccess)
}

/* ANNUAIRE PRO CAT */
export const procatPost = async (xAccess, data) => {
  return await apiService.post('/annuaireprocat', data, xAccess)
}
export const procatsGet = async (xAccess) => {
  return await apiService.get('/annuaireprocats', xAccess)
}
export const procatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/annuaireprocat/${uuid}`, data, xAccess)
}
export const procatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/annuaireprocat/${uuid}`, xAccess)
}
/// ANNUAIRE PRO
export const proPost = async (xAccess, data) => {
  return await apiService.post('/annuairepro', data, xAccess)
}
export const prosGet = async (xAccess, page, range, cat, dep) => {
  const endpoint = `/annuairepros?page=${page}&size=${range}&cat=${cat}&dep=${dep}`
  return await apiService.get(endpoint, xAccess)
}
export const prosExportGet = async (xAccess, cat, dep) => {
  const endpoint = `/annuairepro/export/list?cat=${cat}&dep=${dep}`
  return await apiService.get(endpoint, xAccess)
}
export const proPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/annuairepro/${uuid}`, data, xAccess)
}
export const proDel = async (uuid, xAccess) => {
  return await apiService.delete(`/annuairepro/${uuid}`, xAccess)
}
// ANNUAIRE MUSICIENS CAT
export const musicienscatPost = async (xAccess, data) => {
  return await apiService.post('/musicienscat', data, xAccess)
}
export const musicienscatsGet = async (xAccess) => {
  return await apiService.get('/musicienscats', xAccess)
}
export const musicienscatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/musicienscat/${uuid}`, data, xAccess)
}
export const musicienscatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/musicienscat/${uuid}`, xAccess)
}
// ANNUAIRE MUSICIENS
export const musicienPost = async (xAccess, data) => {
  return await apiService.post('/musicienscat', data, xAccess) // Assuming same endpoint
}
export const musiciensGet = async (xAccess, page, range, cat, dep) => {
  const endpoint = `/musiciens?page=${page}&size=${range}&cat=${cat ? cat : ''}&dep=${dep ? dep : ''}`
  return await apiService.get(endpoint, xAccess)
}
export const musicienPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/musicien/${uuid}`, data, xAccess)
}
export const musicienDel = async (uuid, xAccess) => {
  return await apiService.delete(`/musicien/${uuid}`, xAccess)
}

/* ACCUEIL */

/* COUP DE COEUR */
export const coupdecoeurPost = async (xAccess, data) => {
  return await apiService.post('/coupdecoeur', data, xAccess)
}
export const coupdecoeurGets = async (xAccess) => {
  return await apiService.get('/coupdecoeur', xAccess)
}
export const coupdecoeurGet = async (uuid, xAccess) => {
  return await apiService.get(`/coupdecoeur/${uuid}`, xAccess)
}
export const coupdecoeurPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/coupdecoeur/${uuid}`, data, xAccess)
}
export const coupdecoeurStatutPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/coupdecoeur-activate/${uuid}`, data, xAccess)
}
export const coupdecoeurDel = async (uuid, xAccess) => {
  return await apiService.delete(`/coupdecoeur/${uuid}`, xAccess)
}

/* PLEIN FEUX */
export const pleinfeuxPost = async (xAccess, data) => {
  return await apiService.post('/pleinfeux', data, xAccess)
}
export const pleinfeuxGets = async (xAccess) => {
  return await apiService.get('/pleinfeux', xAccess)
}
export const pleinfeuxGet = async (uuid, xAccess) => {
  return await apiService.get(`/pleinfeux/${uuid}`, xAccess)
}
export const pleinfeuxPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/pleinfeux/${uuid}`, data, xAccess)
}
export const pleinfeuxStatutPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/pleinfeux-activate/${uuid}`, data, xAccess)
}
export const pleinfeuxDel = async (uuid, xAccess) => {
  return await apiService.delete(`/pleinfeux/${uuid}`, xAccess)
}
export const homeContentGet = async (uuid) => {
  return await apiService.get(`/home/${uuid}`)
}
export const homeContentPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/home/${uuid}`, data, xAccess)
}

/* ANNONCES */
export const annoncesGets = async (page, range, cat, dep) => {
  const endpoint = `/pas?page=${page}&size=${range}&cat=${cat}&dep=${dep}`
  return await apiService.get(endpoint)
}
export const vendresGet = async (page, range, cat, dep) => {
  const endpoint = `/pas-vendre?page=${page}&size=${range}&cat=${cat}&dep=${dep}`
  return await apiService.get(endpoint)
}
export const vendreDel = async (uuid, xAccess) => {
  const endpoint = `/pa/${uuid}`
  return await apiService.delete(endpoint, xAccess)
}
export const acheterGet = async (page, range, cat, dep) => {
  const endpoint = `/pas-acheter?page=${page}&size=${range}&cat=${cat}&dep=${dep}`
  return await apiService.get(endpoint)
}
export const paGet = async (uuid) => {
  const endpoint = `/pa-uuid/${uuid}`
  return await apiService.get(endpoint)
}
export const paPut = async (uuid, data) => {
  const endpoint = `/pa/${uuid}`
  return await apiService.put(endpoint, data)
}
export const paDel = async (uuid, xAccess) => {
  const endpoint = `/pa/${uuid}`
  return await apiService.delete(endpoint, xAccess)
}

/* ANNONCES CAT */
export const vendrecatGets = async () => {
  const endpoint = `/pacats-vendre`
  return await apiService.get(endpoint)
}
export const achetercatGets = async () => {
  const endpoint = `/pacats-acheter`
  return await apiService.get(endpoint)
}
export const annoncescatGets = async (xAccess) => {
  const endpoint = `/pacats`
  return await apiService.get(endpoint, xAccess)
}
export const annoncescatPut = async (uuid, xAccess, data) => {
  const endpoint = `/pacat/${uuid}`
  return await apiService.put(endpoint, data, xAccess)
}
export const annoncescatPost = async (xAccess, data) => {
  const endpoint = `/pacat`
  return await apiService.post(endpoint, data, xAccess)
}
export const annoncescatDel = async (uuid, xAccess) => {
  const endpoint = `/pacat/${uuid}`
  return await apiService.delete(endpoint, xAccess)
}

/* CONCERTS */
export const dataConcertsGets = async (page, range, cat, dep) => {
  return apiService.get(`/concerts?page=${page ? page : ''}&size=${range ? range : ''}&cat=${cat ? cat : ''}&dep=${dep ? dep : ''}`)
}
export const concertGet = async (uuid) => {
  return apiService.get(`/concert-uuid/${uuid}`)
}
export const concertPut = async (uuid, data) => {
  return apiService.put(`/concert/${uuid}`, data)
}

/* CONCERTS CAT */
export const concertscatGets = async (xAccess) => {
  return apiService.get('/concertscat', xAccess)
}
export const concertscatPost = async (xAccess, data) => {
  return apiService.post('/concertscat', data, xAccess)
}
export const concertscatPut = async (uuid, xAccess, data) => {
  return apiService.put(`/concertscat/${uuid}`, data, xAccess)
}

/* ANNONCES EMPLOI */
export const emploisGet = async (page, range, cat, dep) => {
  return apiService.get(`/emplois?page=${page}&size=${range}&cat=${cat ? cat : ''}&dep=${dep ? dep : ''}`)
}
export const emploiGet = async (uuid) => {
  return apiService.get(`/emploi-uuid/${uuid}`)
}
export const emploiPut = async (uuid, data) => {
  return apiService.put(`/emploi/${uuid}`, data)
}

/* EMPLOIS CAT */
export const emploiscatGets = async () => {
  return await apiService.get(`/emploicats`)
}
export const emploicatPost = async (xAccess, data) => {
  return await apiService.post(`/emploicat`, data, xAccess)
}
export const emploicatPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/emploicat/${uuid}`, data, xAccess)
}
export const emploicatDel = async (uuid, xAccess) => {
  return await apiService.delete(`/emploicat/${uuid}`, xAccess)
}

/* DATAS */
export const dataUsersGets = async (xAccess) => {
  return await apiService.get(`/datas/users`, xAccess)
}

/* TICKETS */
export const ticketreponsePost = async (xAccess, data) => {
  return await apiService.post(`/ticket/reponse`, data, xAccess)
}
export const ticketsGets = async (xAccess) => {
  return await apiService.get(`/tickets`, xAccess)
}
export const ticketGetByReponse = async (uuid, xAccess) => {
  return await apiService.get(`/ticket/reponse/${uuid}`, xAccess)
}
export const ticketDel = async (uuid, xAccess) => {
  return await apiService.delete(`/ticket/${uuid}`, xAccess)
}

/* TICKETS TYPES */
export const ticketstypesGets = async (xAccess) => {
  return await apiService.get(`/ticketstypes`, xAccess)
}
export const ticketstypesPost = async (xAccess, data) => {
  return await apiService.post(`/tickettype`, data, xAccess)
}
export const ticketstypesPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/tickettype/${uuid}`, data, xAccess)
}
export const ticketstypesDel = async (uuid, xAccess) => {
  return await apiService.delete(`/tickettype/${uuid}`, xAccess)
}

/* ESPACES PERSO */
export const espacesGet = async (xAccess, page, range, dep) => {
  return await apiService.get(`/espaces?page=${page}&size=${range}&dep=${dep}`, xAccess)
}
export const espacesPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/espace/${uuid}`, data, xAccess)
}

/* ESPACES PERSO IMAGES */
export const espaceImgPost = async (data) => {
  return await apiService.post(`/espaceimage`, data)
}

/* NEWSLETTERS */
export const newslettersGets = async (xAccess) => {
  return await apiService.get(`/news/messages`, xAccess)
}
export const newsletterGet = async (uuid, xAccess) => {
  return await apiService.get(`/news/message/${uuid}`, xAccess)
}
export const newsletterPost = async (xAccess, data) => {
  return await apiService.post(`/news/message`, data, xAccess)
}
export const newsletterPut = async (uuid, xAccess, data) => {
  return await apiService.put(`/news/message/${uuid}`, data, xAccess)
}
export const newsletterDel = async (uuid, xAccess) => {
  return await apiService.delete(`/news/${uuid}`, xAccess)
}
export const newsletterExpePost = async (uuid, xAccess, data) => {
  return await apiService.post(`/news/expedition/${uuid}`, data, xAccess)
}
export const newsletterExpeGroupPost = async (uuid, xAccess, data) => {
  return await apiService.post(`/news/expedition-groupe/${uuid}`, data, xAccess)
}
