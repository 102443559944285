let initialState = {
	login: []
};

const loginUser = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGIN_USER_RETURN':
			return Object.assign({}, state, {login: action.payload});
		default:
			return state;
	}
};

export default loginUser;
