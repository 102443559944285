export const GET_CANDATTENTE_RETURN = 'GET_CANDATTENTE_RETURN';
export const GET_CANDATTENTE_SAGAS = 'GET_CANDATTENTE_SAGAS';

export const GET_CANDIDAT_RETURN = 'GET_CANDIDAT_RETURN';
export const GET_CANDIDAT_SAGAS = 'GET_CANDIDAT_SAGAS';

export const PUT_CANDIDAT_RETURN = 'PUT_CANDIDAT_RETURN';
export const PUT_CANDIDAT_SAGAS = 'PUT_CANDIDAT_SAGAS';

export const DEL_CANDIDAT_RETURN = 'DEL_CANDIDAT_RETURN';
export const DEL_CANDIDAT_SAGAS = 'DEL_CANDIDAT_SAGAS';

//* POST CANDIDAT

//* GET CANDIDAT ATTENTE
export const getCandAttenteSagas = (xAccess) => ({
	type: GET_CANDATTENTE_SAGAS,
	token: xAccess
});
export const getCandAttenteReturn = (result) => ({
	type: GET_CANDATTENTE_RETURN,
	payload: result
});

//* GET CANDIDAT BY UUID
export const getCandidatSagas = (uuid, xAccess) => ({
	type: GET_CANDIDAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getCandidatReturn = (result) => ({
	type: GET_CANDIDAT_RETURN,
	payload: result
});

//* PUT CANDIDAT
export const putCandidatSagas = (uuid, xAccess, data) => ({
	type: PUT_CANDIDAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putCandidatReturn = (result) => ({
	type: PUT_CANDIDAT_RETURN,
	payload: result
});

//* DELETE CANDIDAT
export const deleteCandidatSagas = (uuid, xAccess) => ({
	type: DEL_CANDIDAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteTCandidatReturn = (result) => ({
	type: DEL_CANDIDAT_RETURN,
	payload: result
});
