export const GET_USERS_RETURN = 'GET_USERS_RETURN';
export const GET_USERS_SAGAS = 'GET_USERS_SAGAS';

export const PUT_USER_RETURN = 'PUT_USER_RETURN';
export const PUT_USER_SAGAS = 'PUT_USER_SAGAS';

/********** USERS GET ALL ****************/
export const getUsersSagas = (xAccess, page, range) => ({
	type: GET_USERS_SAGAS,
	token: xAccess,
	page,
	range
});
export const getUsersReturn = (result) => ({
	type: GET_USERS_RETURN,
	payload: result
});

/********** USERS UPDATE ****************/
export const putUserSagas = (uuid, xAccess, data) => ({
	type: PUT_USER_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putUserReturn = (result) => ({
	type: PUT_USER_RETURN,
	payload: result
});
