import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getVendreInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_VENDRES_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'GET_VENDRE_RETURN':
			return Object.assign({}, state, {one: action.payload});

		case 'PUT_VENDRE_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Annonce mise à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				one: action.payload
			});
		case 'DEL_VENDRE_SAGAS':
			const vendres = state.infos.data.items;
			const indexVendre = vendres.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.infos, data: vendres.filter((item, i) => i !== indexVendre)};

			return Object.assign({}, state, {infos: newStateDelete});
		case 'DEL_VENDRE_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Annonce supprimé !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getVendreInfos;
