export const GET_DATASUSERS_RETURN = 'GET_DATASUSERS_RETURN';
export const GET_DATASUSERS_SAGAS = 'GET_DATASUSERS_SAGAS';

//* GET ALL USERS
export const getDatasUsersSagas = (xAccess) => ({
	type: GET_DATASUSERS_SAGAS,
	token: xAccess
});
export const getDatasUsersReturn = (result) => ({
	type: GET_DATASUSERS_RETURN,
	payload: result
});
