import React, {Suspense, lazy} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {history} from './history';
import {connect} from 'react-redux';
import Spinner from './components/@vuexy/spinner/Loading-spinner';
import PrivateRoutesSU from './helpers/protected/auth_su.protected';
import PrivateRoutesAdmin from './helpers/protected/admin.protected';

import {ContextLayout} from './utility/context/Layout';

const login = lazy(() => import('./views/pages/authentication/login/Login'));
const passeoubli = lazy(() => import('./views/pages/authentication/login/ForgotPassword'));
const validation = lazy(() => import('./views/pages/authentication/login/ResetPassword'));

const Home = lazy(() => import('./views/pages/Home'));
const Administrateurs = lazy(() => import('./views/administrateurs/Index'));

//*********** UTILISATEURS **************/
const Utilisateurs = lazy(() => import('./views/utilisateurs/Utilisateurs'));

//*********** TREMPLINS **************/
const Tremplins = lazy(() => import('./views/tremplins/tremplins/actif'));
const TremplinsInactif = lazy(() => import('./views/tremplins/tremplins/inactif'));
const TremplinsTermine = lazy(() => import('./views/tremplins/tremplins/termine'));
const TremplinsCloture = lazy(() => import('./views/tremplins/tremplins/cloture'));

const TremplinsAjouter = lazy(() => import('./views/tremplins/tremplins/Ajouter'));
const TremplinsModifier = lazy(() => import('./views/tremplins/tremplins/modifier'));
const TremplinsInfo = lazy(() => import('./views/tremplins/tremplins/info'));
const TremplinsCats = lazy(() => import('./views/tremplins/categories/list'));
const TremplinscatsAjouter = lazy(() => import('./views/tremplins/categories/Ajouter'));
const TremplinscatsModifier = lazy(() => import('./views/tremplins/categories/modifier'));
const TremplinscatsOrdonner = lazy(() => import('./views/tremplins/categories/ordonner'));
const TremplinsCandidat = lazy(() => import('./views/tremplins/candidatures/list'));
const TremplinsCandidatModifier = lazy(() => import('./views/tremplins/candidatures/modifier'));

//*********** BLOGS **************/
const Blogs = lazy(() => import('./views/blog/blog/list'));
const BlogAjouter = lazy(() => import('./views/blog/blog/Ajouter'));
const BlogModifier = lazy(() => import('./views/blog/blog/Modifier'));
const BlogCat = lazy(() => import('./views/blog/categories/list'));

//*********** PARRAINS **************/
const Parrains = lazy(() => import('./views/parrains/list'));
const ParrainAjouter = lazy(() => import('./views/parrains/Ajouter'));
const ParrainModifier = lazy(() => import('./views/parrains/modifier'));

//*********** BANCS D'ESSAI **************/
const Bancs = lazy(() => import('./views/bancs/bancs/list'));
const BancsAjouter = lazy(() => import('./views/bancs/bancs/Ajouter'));
const BancsModifer = lazy(() => import('./views/bancs/bancs/modifier'));
const Bancscat = lazy(() => import('./views/bancs/categories/list'));
const Bancstesteurs = lazy(() => import('./views/bancs/testeurs/list'));
const TesteursAjouter = lazy(() => import('./views/bancs/testeurs/Ajouter'));
const TesteursModifier = lazy(() => import('./views/bancs/testeurs/modifier'));
const Bancsrevendeurs = lazy(() => import('./views/bancs/revendeurs/list'));
const RevendeursAjouter = lazy(() => import('./views/bancs/revendeurs/Ajouter'));

//*********** CONTENU ACCUEIL **************/
const AccueilCoeur = lazy(() => import('./views/accueil/coupdecoeur/list'));
const AccueilCoeurAjouter = lazy(() => import('./views/accueil/coupdecoeur/Ajouter'));
const AccueilCoeurModifier = lazy(() => import('./views/accueil/coupdecoeur/modifier'));
const AccueilFeux = lazy(() => import('./views/accueil/pleinfeux/list'));
const AccueilFeuxAjouter = lazy(() => import('./views/accueil/pleinfeux/Ajouter'));
const AccueilFeuxModifier = lazy(() => import('./views/accueil/pleinfeux/modifier'));
const AccueilMessage = lazy(() => import('./views/accueil/message/index'));

//*********** MARQUES **************/
const Marques = lazy(() => import('./views/marques/list'));

//*********** DEPARTEMENTS **************/
const Departements = lazy(() => import('./views/departements/list'));

//*********** ANNUAIRE PRO **************/
const Pro = lazy(() => import('./views/annuairepro/annuaire/list'));
const ProAjouter = lazy(() => import('./views/annuairepro/annuaire/Ajouter'));
const Procats = lazy(() => import('./views/annuairepro/categories/list'));

//*********** ANNUAIRE MUSICIENS **************/
const Musiciens = lazy(() => import('./views/annuairemusiciens/annuaire/list'));
const MusicienAjouter = lazy(() => import('./views/annuairemusiciens/annuaire/Ajouter'));
const Musicienscats = lazy(() => import('./views/annuairemusiciens/categories/list'));

//*********** ANNONCES **************/
const AnnoncesVendre = lazy(() => import('./views/annonces/vendre/list'));
const AnnoncesAcheter = lazy(() => import('./views/annonces/acheter/list'));
const AnnoncesModifier = lazy(() => import('./views/annonces/modifier'));
const AnnoncesCat = lazy(() => import('./views/annonces/categories/list'));

//*********** EMPLOIS **************/
const AnnoncesEmplois = lazy(() => import('./views/annonces/emplois/list'));
const EmploiModif = lazy(() => import('./views/annonces/emplois/modifier'));
const EmploisCat = lazy(() => import('./views/annonces/emplois/categories/list'));

//*********** CONCERTS **************/
const AnnoncesConcerts = lazy(() => import('./views/annonces/concerts/list'));
const ConcertModif = lazy(() => import('./views/annonces/concerts/modifier'));
const ConcertsCat = lazy(() => import('./views/annonces/concerts/list'));

//*********** CONTATC **************/
const Contact = lazy(() => import('./views/contact/contact/list'));
const Contacttypes = lazy(() => import('./views/contact/types/list'));
const Contactsuivi = lazy(() => import('./views/contact/contact/info'));

//*********** ESPACES PERSO **************/
const Espaces = lazy(() => import('./views/espaces/list'));
const Espacetest = lazy(() => import('./views/espaces/modifier'));

//*********** NEWSLETTER **************/
const Newsletter = lazy(() => import('./views/newsletter/list'));
const NewsletterAjout = lazy(() => import('./views/newsletter/Ajouter'));
const NewsletterExpedier = lazy(() => import('./views/newsletter/expedier'));

// Set Layout and Component Using App Route
const RouteConfig = ({component: Component, fullLayout, permission, user, ...rest}) => (
	<Route
		{...rest}
		render={(props) => {
			return (
				<ContextLayout.Consumer>
					{(context) => {
						let LayoutTag = fullLayout === true ? context.fullLayout : context.state.activeLayout === 'horizontal' ? context.horizontalLayout : context.VerticalLayout;
						return (
							<LayoutTag {...props} permission={props.user}>
								<Suspense fallback={<Spinner />}>
									<Component {...props} />
								</Suspense>
							</LayoutTag>
						);
					}}
				</ContextLayout.Consumer>
			);
		}}
	/>
);
const mapStateToProps = (state) => {
	return {
		user: state.auth.login?.userRole
	};
};

const AuthSu = ({component: Component, fullLayout, permission, ...rest}) => (
	<PrivateRoutesSU
		{...rest}
		render={(props) => {
			return (
				<ContextLayout.Consumer>
					{(context) => {
						let LayoutTag = fullLayout === true ? context.fullLayout : context.state.activeLayout === 'horizontal' ? context.horizontalLayout : context.VerticalLayout;
						return (
							<LayoutTag {...props}>
								<Suspense fallback={<Spinner />}>
									<Component {...props} />
								</Suspense>
							</LayoutTag>
						);
					}}
				</ContextLayout.Consumer>
			);
		}}
	/>
);

const AuthAdmin = ({component: Component, fullLayout, permission, ...rest}) => (
	<PrivateRoutesAdmin
		{...rest}
		render={(props) => {
			return (
				<ContextLayout.Consumer>
					{(context) => {
						let LayoutTag = fullLayout === true ? context.fullLayout : context.state.activeLayout === 'horizontal' ? context.horizontalLayout : context.VerticalLayout;
						return (
							<LayoutTag {...props}>
								<Suspense fallback={<Spinner />}>
									<Component {...props} />
								</Suspense>
							</LayoutTag>
						);
					}}
				</ContextLayout.Consumer>
			);
		}}
	/>
);

const AppRoute = connect(mapStateToProps)(RouteConfig);

class AppRouter extends React.Component {
	render() {
		return (
			// Set the directory path if you are deploying in sub-folder
			<Router history={history}>
				<Switch>
					<AuthAdmin exact path="/" component={Home} />
					<AuthSu path="/utilisateurs" component={Utilisateurs} />
					<AuthSu path="/administrateurs" component={Administrateurs} />

					<AuthAdmin exact path="/tremplins" component={Tremplins} />
					<AuthAdmin exact path="/tremplins/inactifs" component={TremplinsInactif} />
					<AuthAdmin exact path="/tremplins/termines" component={TremplinsTermine} />
					<AuthAdmin exact path="/tremplins/clotures" component={TremplinsCloture} />
					<AuthAdmin exact path="/tremplins/ajouter" component={TremplinsAjouter} />
					<AuthAdmin exact path="/tremplins/modifier/:uuid" component={TremplinsModifier} />
					<AuthAdmin exact path="/tremplins/info/:uuid" component={TremplinsInfo} />
					<AuthAdmin exact path="/tremplins/categories" component={TremplinsCats} />
					<AuthAdmin exact path="/tremplins/categories/ajouter" component={TremplinscatsAjouter} />
					<AuthAdmin exact path="/tremplins/categories/modifier/:uuid" component={TremplinscatsModifier} />
					<AuthAdmin exact path="/tremplins/categories/ordonner" component={TremplinscatsOrdonner} />
					<AuthAdmin exact path="/tremplins/candidatures" component={TremplinsCandidat} />
					<AuthAdmin exact path="/tremplins/candidature/modifier/:uuid" component={TremplinsCandidatModifier} />

					<AuthAdmin exact path="/blog" component={Blogs} />
					<AuthAdmin exact path="/blog/ajouter" component={BlogAjouter} />
					<AuthAdmin exact path="/blog/modifier/:uuid" component={BlogModifier} />
					<AuthAdmin exact path="/blog/categories" component={BlogCat} />

					<AuthAdmin exact path="/parrains" component={Parrains} />
					<AuthAdmin exact path="/parrains/ajouter" component={ParrainAjouter} />
					<AuthAdmin exact path="/parrains/modifier/:uuid" component={ParrainModifier} />

					<AuthAdmin exact path="/bancs" component={Bancs} />
					<AuthAdmin exact path="/bancs/ajouter" component={BancsAjouter} />
					<AuthAdmin exact path="/bancs/modifier/:uuid" component={BancsModifer} />
					<AuthAdmin exact path="/bancs/categories" component={Bancscat} />
					<AuthAdmin exact path="/bancs/testeurs" component={Bancstesteurs} />
					<AuthAdmin exact path="/bancs/testeurs/ajouter" component={TesteursAjouter} />
					<AuthAdmin exact path="/bancs/testeurs/modifier/:uuid" component={TesteursModifier} />
					<AuthAdmin exact path="/bancs/revendeurs" component={Bancsrevendeurs} />
					<AuthAdmin exact path="/bancs/revendeurs/ajouter" component={RevendeursAjouter} />

					<AuthAdmin exact path="/accueil/coupdecoeur" component={AccueilCoeur} />
					<AuthAdmin exact path="/accueil/coupdecoeur/ajouter" component={AccueilCoeurAjouter} />
					<AuthAdmin exact path="/accueil/coupdecoeur/modifier/:uuid" component={AccueilCoeurModifier} />
					<AuthAdmin exact path="/accueil/pleinfeux" component={AccueilFeux} />
					<AuthAdmin exact path="/accueil/pleinfeux/ajouter" component={AccueilFeuxAjouter} />
					<AuthAdmin exact path="/accueil/pleinfeux/modifier/:uuid" component={AccueilFeuxModifier} />
					<AuthAdmin exact path="/accueil/message" component={AccueilMessage} />

					<AuthAdmin exact path="/marques" component={Marques} />
					<AuthAdmin exact path="/departements" component={Departements} />

					<AuthAdmin exact path="/annuaire-pro" component={Pro} />
					<AuthAdmin exact path="/annuaire-pro/ajouter" component={ProAjouter} />
					<AuthAdmin exact path="/annuaire-pro/categories" component={Procats} />
					<AuthAdmin exact path="/annuaire-musiciens" component={Musiciens} />
					<AuthAdmin exact path="/annuaire-musiciens/ajouter" component={MusicienAjouter} />
					<AuthAdmin exact path="/annuaire-musiciens/categories" component={Musicienscats} />

					<AuthAdmin exact path="/annonces/categories" component={AnnoncesCat} />
					<AuthAdmin exact path="/annonces/concert/categories" component={ConcertsCat} />
					<AuthAdmin exact path="/annonces/vendre" component={AnnoncesVendre} />
					<AuthAdmin exact path="/annonces/acheter" component={AnnoncesAcheter} />
					<AuthAdmin exact path="/annonces/modifier/:uuid" component={AnnoncesModifier} />
					<AuthAdmin exact path="/annonces/concerts" component={AnnoncesConcerts} />
					<AuthAdmin exact path="/annonces/emplois" component={AnnoncesEmplois} />
					<AuthAdmin exact path="/annonces/emplois/modifier/:uuid" component={EmploiModif} />
					<AuthAdmin exact path="/annonces/emplois/categories" component={EmploisCat} />
					<AuthAdmin exact path="/annonces/concerts/modifier/:uuid" component={ConcertModif} />

					<AuthAdmin exact path="/contact" component={Contact} />
					<AuthAdmin exact path="/contact/types" component={Contacttypes} />
					<AuthAdmin exact path="/contact/suivi/:uuid" component={Contactsuivi} />

					<AuthAdmin exact path="/espaces" component={Espaces} />
					<AuthAdmin exact path="/espaces/test" component={Espacetest} />

					<AuthAdmin exact path="/newsletter" component={Newsletter} />
					<AuthAdmin exact path="/newsletter/ajouter" component={NewsletterAjout} />
					<AuthAdmin exact path="/newsletter/expedier/:uuid" component={NewsletterExpedier} />

					<AppRoute path="/login" component={login} fullLayout />
					<AppRoute path="/pages/passeoubli" component={passeoubli} fullLayout />
					<AppRoute path="/validation-token/:token" component={validation} fullLayout />
				</Switch>
			</Router>
		);
	}
}

export default AppRouter;
