import React from 'react';
import * as Icon from 'react-feather';

export const myConfig = (nbTremplins, nbTickets) => {
	const navigationConfig = [
		{
			id: 'home',
			title: 'Accueil',
			type: 'item',
			icon: <Icon.Home size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/'
		},
		{
			type: 'groupHeader',
			groupTitle: 'Rubriques'
		},
		{
			id: 'contact',
			title: 'Contact',
			type: 'item',
			icon: <Icon.Mail size={20} />,
			badge: 'success',
			badgeText: nbTickets,
			permissions: ['admin', 'editor'],
			navLink: '/contact'
		},
		{
			id: 'blo',
			title: 'Blog',
			type: 'item',
			icon: <Icon.MessageSquare size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/blog'
		},
		{
			id: 'tremplins',
			title: 'Tremplins',
			type: 'collapse',
			icon: <Icon.Mic size={20} />,
			badge: 'success',
			badgeText: nbTremplins,
			children: [
				{
					id: 'trempcabdid',
					title: 'Candidatures',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/tremplins/candidatures'
				},
				{
					id: 'tremp',
					title: 'Tremplins',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/tremplins'
				},
				{
					id: 'trempcat',
					title: 'Catégories',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/tremplins/categories'
				}
			]
		},
		{
			id: 'parrain',
			title: 'Parrains',
			type: 'item',
			icon: <Icon.Heart size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/parrains'
		},
		{
			id: 'essai',
			title: "Bancs d'essais",
			type: 'collapse',
			icon: <Icon.Camera size={20} />,
			children: [
				{
					id: 'bancs',
					title: 'Bancs',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/bancs'
				},
				{
					id: 'bancscat',
					title: 'Catégories',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/bancs/categories'
				},
				{
					id: 'bancstesteurs',
					title: 'Testeurs',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/bancs/testeurs'
				},
				{
					id: 'bancsrevendeurs',
					title: 'Revendeurs',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/bancs/revendeurs'
				}
			]
		},
		{
			type: 'groupHeader',
			groupTitle: 'Annonces & Annuaires'
		},
		{
			id: 'annonces',
			title: 'Annonces',
			type: 'collapse',
			icon: <Icon.BookOpen size={20} />,
			children: [
				{
					id: 'vendre',
					title: 'Vendre',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/annonces/vendre'
				},
				{
					id: 'acheter',
					title: 'Acheter',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/annonces/acheter'
				},
				{
					id: 'concert',
					title: 'Concerts',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/annonces/concerts'
				},
				{
					id: 'emploi',
					title: "Offres d'emplois",
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/annonces/emplois'
				}
			]
		},
		{
			id: 'annuaire',
			title: 'Annuaires',
			type: 'collapse',
			icon: <Icon.FileText size={20} />,
			children: [
				{
					id: 'analyticsDash',
					title: 'Professionnels',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/annuaire-pro'
				},
				{
					id: 'eCommerceDash',
					title: 'Musiciens',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/annuaire-musiciens'
				}
			]
		},
		{
			id: 'perso',
			title: 'Espaces Perso',
			type: 'item',
			icon: <Icon.Heart size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/espaces'
		},
		{
			type: 'groupHeader',
			groupTitle: 'Gestions utilisateurs'
		},
		{
			id: 'util',
			title: 'Utilisateurs',
			type: 'item',
			icon: <Icon.Users size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/utilisateurs'
		},
		{
			id: 'admin',
			title: 'Administrateurs',
			type: 'item',
			icon: <Icon.User size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/administrateurs'
		},
		{
			id: 'news',
			title: 'Newsletter',
			type: 'item',
			icon: <Icon.AtSign size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/newsletter'
		},
		{
			type: 'groupHeader',
			groupTitle: 'Divers'
		},
		{
			id: 'homePage',
			title: 'Contenu Accueil',
			type: 'collapse',
			icon: <Icon.BookOpen size={20} />,
			children: [
				{
					id: 'message',
					title: 'Message',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/accueil/message'
				},
				{
					id: 'coeur',
					title: 'Coup de Coeur',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin', 'editor'],
					navLink: '/accueil/coupdecoeur'
				},
				{
					id: 'feux',
					title: 'Plein feux',
					type: 'item',
					icon: <Icon.Circle size={12} />,
					permissions: ['admin'],
					navLink: '/accueil/pleinfeux'
				}
			]
		},
		{
			id: 'dep',
			title: 'Départements',
			type: 'item',
			icon: <Icon.MapPin size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/departements'
		},
		{
			id: 'marque',
			title: 'Marques',
			type: 'item',
			icon: <Icon.Flag size={20} />,
			permissions: ['admin', 'editor'],
			navLink: '/marques'
		}
	];
	return navigationConfig;
};
