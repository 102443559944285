import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostMarque(action) {
	try {
		const result = yield fromApi.marquesPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_MARQUE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetMarques(action) {
	try {
		const result = yield fromApi.marquesGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_MARQUES_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutMarque(action) {
	try {
		const result = yield fromApi.marquePut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_MARQUE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelMarque(action) {
	try {
		const result = yield fromApi.marqueDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_MARQUE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* marques() {
	yield takeLatest('POST_MARQUE_SAGAS', watchPostMarque);
	yield takeLatest('GET_MARQUES_SAGAS', watchGetMarques);
	yield takeLatest('PUT_MARQUE_SAGAS', watchPutMarque);
	yield takeLatest('DEL_MARQUE_SAGAS', watchDelMarque);
}

export default function* rootSaga() {
	yield all([marques()]);
}
