import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetBlogcats(action) {
	try {
		const result = yield fromApi.blogscatGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BLOGCATS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetBlogcat(action) {
	try {
		const result = yield fromApi.blogcatGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BLOGCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPostBlogcats(action) {
	try {
		const result = yield fromApi.blogscatPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_BLOGCATS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutBlogcat(action) {
	try {
		const result = yield fromApi.blogcatPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_BLOGCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelBlogcat(action) {
	try {
		const result = yield fromApi.blogcatDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_BLOGCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* blogcats() {
	yield takeLatest('GET_BLOGCATS_SAGAS', watchGetBlogcats);
	yield takeLatest('POST_BLOGCATS_SAGAS', watchPostBlogcats);
	yield takeLatest('GET_BLOGCAT_SAGAS', watchGetBlogcat);
	yield takeLatest('PUT_BLOGCAT_SAGAS', watchPutBlogcat);
	yield takeLatest('DEL_BLOGCAT_SAGAS', watchDelBlogcat);
}

export default function* rootSaga() {
	yield all([blogcats()]);
}
