import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetMessageHome(action) {
	try {
		const result = yield fromApi.homeContentGet(action.payload).then((data) => {
			return data;
		});
		yield put({type: 'GET_CONTENT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPutMessageHome(action) {
	try {
		const result = yield fromApi.homeContentPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_CONTENT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* message() {
	yield takeLatest('GET_CONTENT_SAGAS', watchGetMessageHome);
	yield takeLatest('PUT_CONTENT_SAGAS', watchPutMessageHome);
}

export default function* rootSaga() {
	yield all([message()]);
}
