import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getNewslettersInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_NEWSLETTERS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'GET_NEWSLETTER_RETURN':
			return Object.assign({}, state, {one: action.payload});

		case 'POST_NEWSLETTER_RETURN':
			const arrayNews = [action.payload.data];
			let newStateAdd;
			if (action.payload.status === 201) {
				newStateAdd = {...state.infos, data: state.infos.data.concat(arrayNews)};
				const notifySuccess = () =>
					toast.success('Newsletter ajoutée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateAdd = state.infos;
				if (action.payload.status === 400) {
					const notifyError = () =>
						toast.error('Erreur ! merci de le signaler...', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						});
					notifyError();
				}
			}
			return Object.assign({}, state, {
				create: action.payload,
				infos: newStateAdd
			});

		case 'PUT_NEWSLETTER_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateUpdate = {...state.infos, data: array};
				const notifySuccess = () =>
					toast.success('Newsletter mise à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdate
			});

		case 'POST_NEWSEXPE_RETURN':
			let arrayExpe = [];
			let newStateExpe;
			if (action.payload.status === 202) {
				arrayExpe = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateExpe = {...state.infos, data: arrayExpe};
				const notifySuccess = () =>
					toast.success('Newsletter expédiée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateExpe
			});

		case 'POST_NEWSEXPEGROUP_RETURN':
			let arrayExpeGroup = [];
			let newStateExpeGroup;
			if (action.payload.status === 202) {
				arrayExpeGroup = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateExpeGroup = {...state.infos, data: arrayExpeGroup};
				const notifySuccess = () =>
					toast.success('Newsletter expédiée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateExpeGroup
			});

		case 'DEL_NEWSLETTER_SAGAS':
			const news = state.infos.data;
			const indexNews = news.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.infos, data: news.filter((item, i) => i !== indexNews)};

			return Object.assign({}, state, {infos: newStateDelete});

		case 'DEL_NEWSLETTER_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Newsletter supprimée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getNewslettersInfos;
