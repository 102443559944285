export const POST_DEPARTEMENT_RETURN = 'POST_DEPARTEMENT_RETURN';
export const POST_DEPARTEMENT_SAGAS = 'POST_DEPARTEMENT_SAGAS';

export const GET_DEPARTEMENTS_RETURN = 'GET_DEPARTEMENTS_RETURN';
export const GET_DEPARTEMENTS_SAGAS = 'GET_DEPARTEMENTS_SAGAS';

export const PUT_DEPARTEMENT_RETURN = 'PUT_DEPARTEMENT_RETURN';
export const PUT_DEPARTEMENT_SAGAS = 'PUT_DEPARTEMENT_SAGAS';

export const DEL_DEPARTEMENT_RETURN = 'DEL_DEPARTEMENT_RETURN';
export const DEL_DEPARTEMENT_SAGAS = 'DEL_DEPARTEMENT_SAGAS';

//* POST DEPARTEMENTS
export const postDepartementSagas = (xAccess, data) => ({
	type: POST_DEPARTEMENT_SAGAS,
	token: xAccess,
	data: data
});
export const postDepartementReturn = (result) => ({
	type: POST_DEPARTEMENT_RETURN,
	payload: result
});

//* GET ALL DEPARTEMENTS
export const getDepartementsSagas = (xAccess) => ({
	type: GET_DEPARTEMENTS_SAGAS,
	token: xAccess
});
export const getDepartementsReturn = (result) => ({
	type: GET_DEPARTEMENTS_RETURN,
	payload: result
});

//* GET ONE DEPARTEMENTS

//* PUT DEPARTEMENTS
export const putDepartementSagas = (uuid, xAccess, data) => ({
	type: PUT_DEPARTEMENT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putDepartementReturn = (result) => ({
	type: PUT_DEPARTEMENT_RETURN,
	payload: result
});

//* DELETE DEPARTEMENTS
export const deleteDepartementSagas = (uuid, xAccess) => ({
	type: DEL_DEPARTEMENT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteDepartementReturn = (result) => ({
	type: DEL_DEPARTEMENT_RETURN,
	payload: result
});
