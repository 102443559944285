export const POST_BANCSCAT_RETURN = 'POST_BANCSCAT_RETURN';
export const POST_BANCSCAT_SAGAS = 'POST_BANCSCAT_SAGAS';

export const GET_BANCSCATS_RETURN = 'GET_BANCSCATS_RETURN';
export const GET_BANCSCATS_SAGAS = 'GET_BANCSCATS_SAGAS';

export const GET_BANCSCAT_RETURN = 'GET_BANCSCAT_RETURN';
export const GET_BANCSCAT_SAGAS = 'GET_BANCSCAT_SAGAS';

export const PUT_BANCSCAT_RETURN = 'PUT_BANCSCAT_RETURN';
export const PUT_BANCSCAT_SAGAS = 'PUT_BANCSCAT_SAGAS';

export const DEL_BANCSCAT_RETURN = 'DEL_BANCSCAT_RETURN';
export const DEL_BANCSCAT_SAGAS = 'DEL_BANCSCAT_SAGAS';

//* POST BANCS CAT
export const postBancscatSagas = (xAccess, data) => ({
	type: POST_BANCSCAT_SAGAS,
	token: xAccess,
	data: data
});
export const postBancscatReturn = (result) => ({
	type: POST_BANCSCAT_RETURN,
	payload: result
});

//* GET ALL BANCS CATS
export const getBancscatsSagas = (xAccess) => ({
	type: GET_BANCSCATS_SAGAS,
	token: xAccess
});
export const getBancscatsReturn = (result) => ({
	type: GET_BANCSCATS_RETURN,
	payload: result
});

//* GET ONE BANCS CATS
export const getBancscatSagas = (uuid, xAccess) => ({
	type: GET_BANCSCAT_RETURN,
	token: xAccess,
	payload: uuid
});
export const getBancscatReturn = (result) => ({
	type: GET_BANCSCAT_SAGAS,
	payload: result
});

//* PUT BANCS CAT
export const putBancscatSagas = (uuid, xAccess, data) => ({
	type: PUT_BANCSCAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putBancscatReturn = (result) => ({
	type: PUT_BANCSCAT_RETURN,
	payload: result
});

//* DELETE BANCS CAT
export const deleteBancscatSagas = (uuid, xAccess) => ({
	type: DEL_BANCSCAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteBancscatReturn = (result) => ({
	type: DEL_BANCSCAT_RETURN,
	payload: result
});
