import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getRevendeursInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_REVENDEURS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'POST_REVENDEUR_RETURN':
			if (action.payload.status === 201) {
				const notifySuccess = () =>
					toast.success('Revendeur ajouté !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				if (action.payload.status === 400) {
					const notifyError = () =>
						toast.error('Erreur ! merci de le signaler...', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						});
					notifyError();
				}
			}
			return Object.assign({}, state, {
				create: action.payload
			});

		case 'PUT_REVENDEUR_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.infos.data.items.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});

				let variable = {...state.infos.data, items: array};
				newStateUpdate = {...state.infos, data: variable};

				const notifySuccess = () =>
					toast.success('Revendeur mis à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateUpdate = state.infos;
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdate
			});

		default:
			return state;
	}
};

export default getRevendeursInfos;
