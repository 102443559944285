import {fork} from 'redux-saga/effects';
import loginUserSagas from './auth/login.saga';
import authUserSagas from './auth/user.saga';
import adminsSagas from './admins/admins.saga';
import usersSagas from './users/users.saga';
import blogsSagas from './blog/blog.saga';
import blogcatsSagas from './blog/blogcat.saga';
import tremplinsSagas from './tremplins/tremplins.saga';
import tremplinscandSagas from './tremplins/tremplinscandidats.saga';
import tremplinsCatSagas from './tremplinscat/tremplinscat.saga';
import tremplinsLaureatSagas from './tremplins/tremplinslaureats.saga';
import parrainsSagas from './parrains/parrains.saga';
import bancsSagas from './bancs/bancs.saga';
import bancsCatSagas from './bancs/bancscat.saga';
import testeursSagas from './bancs/bancstesteurs.saga';
import revendeursSagas from './bancs/revendeurs.saga';
import marquesSagas from './marques/marques.saga';
import departementsSagas from './departements/departements.saga';
import prosSagas from './annuairepro/pro.saga';
import procatsSagas from './annuairepro/procats.saga';
import musiciensSagas from './annuairemusiciens/musiciens.saga';
import musicienscatsSagas from './annuairemusiciens/musicienscats.saga';
import pleinfeuxSagas from './accueil/pleinfeux.saga';
import coupdecoeurSagas from './accueil/coupdecoeur.saga';
import homeContentSagas from './accueil/message.saga';
import annoncesvendreSagas from './annonces/vendre.saga';
import annoncescatSagas from './annonces/annoncescat.saga';
import emploisSagas from './emplois/emplois.saga';
import emploiscatSagas from './emplois/emploiscat.saga';
import datasusersSagas from './datas/datas.saga';
import ticketsSagas from './tickets/tickets.saga';
import ticketstypesSagas from './tickets/ticketstypes.saga';
import concertsSagas from './concerts/concerts.saga';
import concertscatSagas from './concerts/concertscat.saga';
import espacesSagas from './espaces/espaces.saga';
import espaceimgSagas from './espaces/espaceimg.saga';
import newsletersSagas from './newsletters/newsletters.saga';

function* rootSaga() {
	yield fork(loginUserSagas);
	yield fork(authUserSagas);
	yield fork(adminsSagas);
	yield fork(usersSagas);
	yield fork(blogsSagas);
	yield fork(blogcatsSagas);
	yield fork(tremplinsSagas);
	yield fork(tremplinscandSagas);
	yield fork(tremplinsCatSagas);
	yield fork(tremplinsLaureatSagas);
	yield fork(parrainsSagas);
	yield fork(bancsSagas);
	yield fork(bancsCatSagas);
	yield fork(testeursSagas);
	yield fork(revendeursSagas);
	yield fork(marquesSagas);
	yield fork(departementsSagas);
	yield fork(prosSagas);
	yield fork(procatsSagas);
	yield fork(musiciensSagas);
	yield fork(musicienscatsSagas);
	yield fork(pleinfeuxSagas);
	yield fork(coupdecoeurSagas);
	yield fork(homeContentSagas);
	yield fork(annoncesvendreSagas);
	yield fork(annoncescatSagas);
	yield fork(emploisSagas);
	yield fork(emploiscatSagas);
	yield fork(datasusersSagas);
	yield fork(ticketsSagas);
	yield fork(ticketstypesSagas);
	yield fork(concertsSagas);
	yield fork(concertscatSagas);
	yield fork(espacesSagas);
	yield fork(espaceimgSagas);
	yield fork(newsletersSagas);
}

export default rootSaga;
