export const POST_MUSICIENCAT_RETURN = 'POST_MUSICIENCAT_RETURN';
export const POST_MUSICIENCAT_SAGAS = 'POST_MUSICIENCAT_SAGAS';

export const GET_MUSICIENCATS_RETURN = 'GET_MUSICIENCATS_RETURN';
export const GET_MUSICIENCATS_SAGAS = 'GET_MUSICIENCATS_SAGAS';

export const PUT_MUSICIENCAT_RETURN = 'PUT_MUSICIENCAT_RETURN';
export const PUT_MUSICIENCAT_SAGAS = 'PUT_MUSICIENCAT_SAGAS';

export const DEL_MUSICIENCAT_RETURN = 'DEL_MUSICIENCAT_RETURN';
export const DEL_MUSICIENCAT_SAGAS = 'DEL_MUSICIENCAT_SAGAS';

/********** MUSICIENS CAT POST ****************/
export const postMusiciencatSagas = (xAccess, data) => ({
	type: POST_MUSICIENCAT_SAGAS,
	token: xAccess,
	data: data
});
export const postMusiciencatReturn = (result) => ({
	type: POST_MUSICIENCAT_RETURN,
	payload: result
});

/********** MUSICIENS CAT GET ALL ****************/
export const getMusicienscatsSagas = (xAccess) => ({
	type: GET_MUSICIENCATS_SAGAS,
	token: xAccess
});
export const getMusicienscatsReturn = (result) => ({
	type: GET_MUSICIENCATS_RETURN,
	payload: result
});

/********** MUSICIENS CAT GET ONE ****************/

/********** MUSICIENS CAT PUT ****************/
export const putMusiciencatSagas = (uuid, xAccess, data) => ({
	type: PUT_MUSICIENCAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putMusiciencatReturn = (result) => ({
	type: PUT_MUSICIENCAT_RETURN,
	payload: result
});

/********** MUSICIENS CAT DELETE ****************/
export const deleteMusiciencatSagas = (uuid, xAccess) => ({
	type: DEL_MUSICIENCAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteMusiciencatReturn = (result) => ({
	type: DEL_MUSICIENCAT_RETURN,
	payload: result
});
