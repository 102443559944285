import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetMusiciens(action) {
	try {
		const result = yield fromApi.musiciensGet(action.token, action.page, action.range, action.cat, action.dep).then((data) => {
			return data;
		});
		yield put({type: 'GET_MUSICIENS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutMusicien(action) {
	try {
		const result = yield fromApi.musicienPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_MUSICIEN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelMusicien(action) {
	try {
		const result = yield fromApi.musicienDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_MUSICIEN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* musiciens() {
	yield takeLatest('GET_MUSICIENS_SAGAS', watchGetMusiciens);
	yield takeLatest('PUT_MUSICIEN_SAGAS', watchPutMusicien);
	yield takeLatest('DEL_MUSICIEN_SAGAS', watchDelMusicien);
}

export default function* rootSaga() {
	yield all([musiciens()]);
}
