export const POST_REVENDEUR_RETURN = 'POST_REVENDEUR_RETURN';
export const POST_REVENDEUR_SAGAS = 'POST_REVENDEUR_SAGAS';

export const GET_REVENDEURS_RETURN = 'GET_REVENDEURS_RETURN';
export const GET_REVENDEURS_SAGAS = 'GET_REVENDEURS_SAGAS';

export const PUT_REVENDEUR_RETURN = 'PUT_REVENDEUR_RETURN';
export const PUT_REVENDEUR_SAGAS = 'PUT_REVENDEUR_SAGAS';

//* POST REVENDEUR
export const postRevendeurSagas = (xAccess, data) => ({
	type: POST_REVENDEUR_SAGAS,
	token: xAccess,
	data: data
});
export const postRevendeurReturn = (result) => ({
	type: POST_REVENDEUR_RETURN,
	payload: result
});

//* REVENDEURS GET ALL
export const getRevendeursSagas = (xAccess, page, range, dep, marque) => ({
	type: GET_REVENDEURS_SAGAS,
	token: xAccess,
	page,
	range,
	dep,
	marque
});
export const getRevendeursReturn = (result) => ({
	type: GET_REVENDEURS_RETURN,
	payload: result
});

//* PUT REVENDEUR
export const putRevendeurSagas = (uuid, xAccess, data) => ({
	type: PUT_REVENDEUR_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putRevendeurReturn = (result) => ({
	type: PUT_REVENDEUR_RETURN,
	payload: result
});
