let initialState = {
	infos: [],
	one: [],
	create: [],
	update: []
};

const getDatasInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_DATASUSERS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		default:
			return state;
	}
};

export default getDatasInfos;
