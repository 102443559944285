export const GET_TREMPLINSCATS_RETURN = 'GET_TREMPLINSCATS_RETURN';
export const GET_TREMPLINSCATS_SAGAS = 'GET_TREMPLINSCATS_SAGAS';

export const GET_TREMPLINCAT_RETURN = 'GET_TREMPLINCAT_RETURN';
export const GET_TREMPLINCAT_SAGAS = 'GET_TREMPLINCAT_SAGAS';

export const GET_CAT_TREMPLINS_RETURN = 'GET_CAT_TREMPLINS_RETURN';
export const GET_CAT_TREMPLINS_SAGAS = 'GET_CAT_TREMPLINS_SAGAS';

export const POST_TREMPLINSCATS_RETURN = 'POST_TREMPLINSCATS_RETURN';
export const POST_TREMPLINSCATS_SAGAS = 'POST_TREMPLINSCATS_SAGAS';

export const PUT_TREMPLINCAT_RETURN = 'PUT_TREMPLINCAT_RETURN';
export const PUT_TREMPLINCAT_SAGAS = 'PUT_TREMPLINCAT_SAGAS';

export const PUT_TREMPLINCATORDRE_RETURN = 'PUT_TREMPLINCATORDRE_RETURN';
export const PUT_TREMPLINCATORDRE_SAGAS = 'PUT_TREMPLINCATORDRE_SAGAS';

export const DEL_TREMPLINCAT_RETURN = 'DEL_TREMPLINCAT_RETURN';
export const DEL_TREMPLINCAT_SAGAS = 'DEL_TREMPLINCAT_SAGAS';

//* GET ALL TREMPLINS CATS
export const getTremplinscatSagas = (xAccess) => ({
	type: GET_TREMPLINSCATS_SAGAS,
	token: xAccess
});
export const getTremplinscatReturn = (result) => ({
	type: GET_TREMPLINSCATS_RETURN,
	payload: result
});

//* GET ONE TREMPLIN CATS
export const getTremplincatSagas = (uuid, xAccess) => ({
	type: GET_TREMPLINCAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getTremplincatReturn = (result) => ({
	type: GET_TREMPLINCAT_RETURN,
	payload: result
});

//* GET ALL TREMPLINS by CAT
export const getCatTremplinsSagas = (uuid, xAccess) => ({
	type: GET_CAT_TREMPLINS_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getCatTremplinsReturn = (result) => ({
	type: GET_CAT_TREMPLINS_RETURN,
	payload: result
});

//* POST TREMPLIN CATS
export const postTremplinscatSagas = (xAccess, data) => ({
	type: POST_TREMPLINSCATS_SAGAS,
	token: xAccess,
	data: data
});
export const postTremplinscatReturn = (result) => ({
	type: POST_TREMPLINSCATS_RETURN,
	payload: result
});

//* PUT TREMPLIN CATS
export const putTremplinscatSagas = (uuid, xAccess, data) => ({
	type: PUT_TREMPLINCAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putTremplinscatReturn = (result) => ({
	type: PUT_TREMPLINCAT_RETURN,
	payload: result
});

//* PUT TREMPLIN CAT ORDRE
export const putTremplinscatOrdreSagas = (xAccess, data) => ({
	type: PUT_TREMPLINCATORDRE_SAGAS,
	data: data
});
export const putTremplinscatOrdreReturn = (result) => ({
	type: PUT_TREMPLINCATORDRE_RETURN,
	payload: result
});

//* DELETE TREMPLIN CATS
export const deleteTremplinscatSagas = (uuid, xAccess) => ({
	type: DEL_TREMPLINCAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteTremplinscatReturn = (result) => ({
	type: DEL_TREMPLINCAT_RETURN,
	payload: result
});
