export const GET_BLOGS_RETURN = 'GET_BLOGS_RETURN';
export const GET_BLOGS_SAGAS = 'GET_BLOGS_SAGAS';

export const GET_BLOG_RETURN = 'GET_BLOG_RETURN';
export const GET_BLOG_SAGAS = 'GET_BLOG_SAGAS';

export const POST_BLOG_RETURN = 'POST_BLOG_RETURN';
export const POST_BLOG_SAGAS = 'POST_BLOG_SAGAS';

export const PUT_BLOG_RETURN = 'PUT_BLOG_RETURN';
export const PUT_BLOG_SAGAS = 'PUT_BLOG_SAGAS';

export const DEL_BLOG_RETURN = 'DEL_BLOG_RETURN';
export const DEL_BLOG_SAGAS = 'DEL_BLOG_SAGAS';

export const getBlogsSagas = (xAccess) => ({
	type: GET_BLOGS_SAGAS,
	token: xAccess
});
export const getBlogsReturn = (result) => ({
	type: GET_BLOGS_RETURN,
	payload: result
});

export const getBlogSagas = (uuid, xAccess) => ({
	type: GET_BLOG_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getBlogReturn = (result) => ({
	type: GET_BLOGS_RETURN,
	payload: result
});

export const postBlogSagas = (xAccess, data) => ({
	type: POST_BLOG_SAGAS,
	token: xAccess,
	data: data
});
export const postBlogReturn = (result) => ({
	type: POST_BLOG_RETURN,
	payload: result
});

export const putBlogSagas = (uuid, xAccess, data) => ({
	type: PUT_BLOG_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putBlogReturn = (result) => ({
	type: PUT_BLOG_RETURN,
	payload: result
});

export const deleteBlogSagas = (uuid, xAccess) => ({
	type: DEL_BLOG_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteBlogReturn = (result) => ({
	type: DEL_BLOG_RETURN,
	payload: result
});
