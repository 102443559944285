export const Configs = {
	BearerToken:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJtdXNpY29ubGluZUFQSSIsInBob25lIjoiMDY4ODEzNzg0MCIsImVtYWlsIjoiZXJpY21vcmVsMjVAbXNuLmNvbSIsInJ3ZCI6MCwiaWF0IjoxNjU3NzE2MTc5fQ.dJa-M5pKgCPbLqPTyyjI-mWqVGKepThQkWa_lHd6bm0',
	urlApi: 'https://api.mymusiconline.fr',
	// urlApi: "http://localhost:8081",
	typeApi: 'development',
	pays: [
		{
			key: 'FR',
			value: 'France',
			url: 'https://www.musiconline-france.art',
			home: '7adda671-7b99-4b2d-ae7e-405bc61ae3c1'
		},
		{
			key: 'CH',
			value: 'Suisse',
			url: 'https://www.musiconline-suisse.art',
			home: ''
		}
	],
	statutTremplin: [
		{
			key: 0,
			value: 'Inactif'
		},
		{
			key: 1,
			value: 'Actif'
		},
		{
			key: 2,
			value: 'Terminé'
		},
		{
			key: 3,
			value: 'Clôturé'
		}
	],
	statutCandidat: [
		{
			key: 0,
			value: 'En attente'
		},
		{
			key: 1,
			value: 'Candidature acceptée'
		},
		{
			key: 2,
			value: 'Candidature refusée'
		}
	],
	destinataires: [
		{
			value: 'Utilisateurs'
		},
		{
			value: 'Annuaire Pro'
		},
		{
			value: 'Annuaire Musiciens'
		},
		{
			value: 'Liste de test'
		}
	]
};
