import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostRevendeur(action) {
	try {
		const result = yield fromApi.revendeurPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_REVENDEUR_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetRevendeurs(action) {
	try {
		const result = yield fromApi.revendeursGet(action.token, action.page, action.range, action.dep, action.marque).then((data) => {
			return data;
		});
		yield put({type: 'GET_REVENDEURS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutRevendeur(action) {
	try {
		const result = yield fromApi.revendeurPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_REVENDEUR_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* revendeurs() {
	yield takeLatest('POST_REVENDEUR_SAGAS', watchPostRevendeur);
	yield takeLatest('GET_REVENDEURS_SAGAS', watchGetRevendeurs);
	yield takeLatest('PUT_REVENDEUR_SAGAS', watchPutRevendeur);
}

export default function* rootSaga() {
	yield all([revendeurs()]);
}
