import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getEspaceImgInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'POST_ESIMG_RETURN':
			if (action.payload.status === 201) {
				const notifySuccess = () =>
					toast.success('Image ajoutée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				if (action.payload.status === 400) {
					const notifyError = () =>
						toast.error('Erreur ! merci de le signaler...', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						});
					notifyError();
				}
			}
			return Object.assign({}, state, {
				create: action.payload
			});

		default:
			return state;
	}
};

export default getEspaceImgInfos;
