export const GET_ADMINS_RETURN = 'GET_ADMINS_RETURN';
export const GET_ADMINS_SAGAS = 'GET_ADMINS_SAGAS';

export const GET_ADMIN_RETURN = 'GET_ADMIN_RETURN';
export const GET_ADMIN_SAGAS = 'GET_ADMIN_SAGAS';

export const PUT_ADMIN_RETURN = 'PUT_ADMIN_RETURN';
export const PUT_ADMIN_SAGAS = 'PUT_ADMIN_SAGAS';

export const POST_ADMIN_RETURN = 'POST_ADMIN_RETURN';
export const POST_ADMIN_SAGAS = 'POST_ADMIN_SAGAS';

export const DEL_ADMIN_RETURN = 'DEL_ADMIN_RETURN';
export const DEL_ADMIN_SAGAS = 'DEL_ADMIN_SAGAS';

export const getAdminsSagas = (xAccess) => ({
	type: GET_ADMINS_SAGAS,
	token: xAccess
});
export const getAdminsReturn = (result) => ({
	type: GET_ADMINS_RETURN,
	payload: result
});

export const getAdminSagas = (uuid, xAccess) => ({
	type: GET_ADMIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getAdminReturn = (result) => ({
	type: GET_ADMIN_RETURN,
	payload: result
});

export const putAdminSagas = (uuid, xAccess, data) => ({
	type: PUT_ADMIN_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putAdminReturn = (result) => ({
	type: PUT_ADMIN_RETURN,
	payload: result
});

export const postAdminSagas = (xAccess, data) => ({
	type: POST_ADMIN_SAGAS,
	token: xAccess,
	data: data
});
export const postAdminReturn = (result) => ({
	type: POST_ADMIN_RETURN,
	payload: result
});

export const deleteAdminSagas = (uuid, xAccess) => ({
	type: DEL_ADMIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteAdminReturn = (result) => ({
	type: DEL_ADMIN_RETURN,
	payload: result
});
