import React from 'react';
import {UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap';
import * as Icon from 'react-feather';
import Avatar from '../../../components/@vuexy/avatar/AvatarComponent';
import {useDispatch} from 'react-redux';
import {Configs} from '../../../configs/general.config';

const UserDropdown = (props) => {
	const dispatch = useDispatch();
	const logOut = () => {
		dispatch({
			type: 'LOGIN_USER_RETURN',
			payload: []
		});
		dispatch({
			type: 'USER_ONE_RETURN',
			payload: []
		});
	};
	return (
		<DropdownMenu right>
			<DropdownItem divider />
			{Configs.pays.map((item, i) => (
				<DropdownItem key={i} tag="a" href={item.url} target="_blank">
					<Icon.Link size={14} className="mr-50" />
					<span className="align-middle">Site {item.value}</span>
				</DropdownItem>
			))}
			<DropdownItem divider />
			<DropdownItem tag="a" href="/login" onClick={logOut} className="text-danger">
				<Icon.Power size={14} className="mr-50" />
				<span className="align-middle">Déconnexion</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

class NavbarUser extends React.PureComponent {
	render() {
		return (
			<ul className="nav navbar-nav navbar-nav-user float-right">
				<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
					<DropdownToggle tag="a" className="nav-link dropdown-user-link">
						<div className="user-nav d-sm-flex d-none">
							<span className="user-name text-bold-600">{this.props.userName}</span>
							<span className="user-status">Bienvenue</span>
						</div>
						<span data-tour="user">
							<Avatar color="info" className="mr-1 text-uppercase" content={this.props.firstName && this.props.lastName ? this.props.firstName[0] + this.props.lastName[0] : ''} />
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		);
	}
}
export default NavbarUser;
