export const GET_VENDRES_RETURN = 'GET_VENDRES_RETURN';
export const GET_VENDRES_SAGAS = 'GET_VENDRES_SAGAS';

export const GET_VENDRE_RETURN = 'GET_VENDRE_RETURN';
export const GET_VENDRE_SAGAS = 'GET_VENDRE_SAGAS';

export const PUT_VENDRE_RETURN = 'PUT_VENDRE_RETURN';
export const PUT_VENDRE_SAGAS = 'PUT_PA_SAGAS';

export const DEL_VENDRE_RETURN = 'DEL_VENDRE_RETURN';
export const DEL_VENDRE_SAGAS = 'DEL_VENDRE_SAGAS';

/********** VENDRE GET ALL ****************/
export const getVendresSagas = (page, range, cat, dep) => ({
	type: GET_VENDRES_SAGAS,
	page,
	range,
	cat,
	dep
});
export const getVendresReturn = (result) => ({
	type: GET_VENDRES_RETURN,
	payload: result
});

//* GET ONE PA
export const getPaVendreSagas = (uuid) => ({
	type: GET_VENDRE_SAGAS,
	payload: uuid
});
export const getPaVendreReturn = (result) => ({
	type: GET_VENDRE_RETURN,
	payload: result
});

//* UPDATE PA
export const putVendreSagas = (uuid, data) => ({
	type: PUT_VENDRE_SAGAS,
	payload: uuid,
	data: data
});
export const putVendreReturn = (result) => ({
	type: PUT_VENDRE_RETURN,
	payload: result
});

export const deleteVendreSagas = (uuid, xAccess) => ({
	type: DEL_VENDRE_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteVendreReturn = (result) => ({
	type: DEL_VENDRE_RETURN,
	payload: result
});
