export const POST_CONCERTSCAT_RETURN = 'POST_CONCERTSCAT_RETURN';
export const POST_CONCERTSCAT_SAGAS = 'POST_CONCERTSCAT_SAGAS';

export const GET_CONCERTSCAT_RETURN = 'GET_CONCERTSCAT_RETURN';
export const GET_CONCERTSCAT_SAGAS = 'GET_CONCERTSCAT_SAGAS';

export const PUT_CONCERTSCAT_RETURN = 'PUT_CONCERTSCAT_RETURN';
export const PUT_CONCERTSCAT_SAGAS = 'PUT_CONCERTSCAT_SAGAS';

//* POST CONCERTS
export const postConcertscaSagas = (xAccess, data) => ({
	type: POST_CONCERTSCAT_SAGAS,
	token: xAccess,
	data: data
});
export const postConcertscaReturn = (result) => ({
	type: POST_CONCERTSCAT_RETURN,
	payload: result
});

//* GET ALL CONCERTS
export const getConcertscatSagas = (xAccess) => ({
	type: GET_CONCERTSCAT_SAGAS,
	token: xAccess
});
export const getConcertscatReturn = (result) => ({
	type: GET_CONCERTSCAT_RETURN,
	payload: result
});

//* GET ONE CONCERTS

//* PUT CONCERTS
export const putConcertscatSagas = (uuid, xAccess, data) => ({
	type: PUT_CONCERTSCAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putConcertscatReturn = (result) => ({
	type: PUT_CONCERTSCAT_RETURN,
	payload: result
});

//* DELETE CONCERTS
