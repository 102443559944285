import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getPleinInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_PLEINS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'GET_PLEIN_RETURN':
			return Object.assign({}, state, {one: action.payload});

		case 'POST_PLEIN_RETURN':
			const arrayData = [action.payload.data];
			let newStateAdd;
			if (action.payload.status === 201) {
				newStateAdd = {...state.infos, data: state.infos.data.concat(arrayData)};
				const notifySuccess = () =>
					toast.success('Plein feux ajouté !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateAdd = state.infos;
				if (action.payload.status === 400) {
					const notifyError = () =>
						toast.error('Erreur merci de nous le signaler !', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						});
					notifyError();
				}
			}
			return Object.assign({}, state, {
				create: action.payload,
				infos: newStateAdd
			});

		case 'PUT_PLEIN_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateUpdate = {...state.infos, data: array};
				const notifySuccess = () =>
					toast.success('Plein feux mis à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdate,
				one: action.payload
			});

		case 'PUT_PLEINSTATUT_RETURN':
			let arrayStatut = [];
			let newStateUpdateStatut;
			if (action.payload.status === 200) {
				arrayStatut = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateUpdateStatut = {...state.infos, data: arrayStatut};
				const notifySuccess = () =>
					toast.success('Plein feux mis à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdateStatut
			});

		case 'DEL_PLEIN_SAGAS':
			const banc = state.infos.data;
			const indexBanc = banc.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.infos, data: banc.filter((item, i) => i !== indexBanc)};

			return Object.assign({}, state, {infos: newStateDelete});

		case 'DEL_PLEIN_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Plein feux supprimé !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getPleinInfos;
