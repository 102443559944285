export const GET_EMPLOICATS_RETURN = 'GET_EMPLOICATS_RETURN';
export const GET_EMPLOICATS_SAGAS = 'GET_EMPLOICATS_SAGAS';

export const PUT_EMPLOICAT_RETURN = 'PUT_EMPLOICAT_RETURN';
export const PUT_EMPLOICAT_SAGAS = 'PUT_EMPLOICAT_SAGAS';

export const POST_EMPLOICAT_RETURN = 'POST_EMPLOICAT_RETURN';
export const POST_EMPLOICAT_SAGAS = 'POST_EMPLOICAT_SAGAS';

export const DEL_EMPLOICAT_RETURN = 'DEL_EMPLOICAT_RETURN';
export const DEL_EMPLOICAT_SAGAS = 'DEL_EMPLOICAT_SAGAS';

//* GET CAT ALL
export const getEmploicatsSagas = () => ({
	type: GET_EMPLOICATS_SAGAS
});
export const getEmploicatsReturn = (result) => ({
	type: GET_EMPLOICATS_RETURN,
	payload: result
});

//* PUT PA CAT
export const putEmploicatSagas = (uuid, xAccess, data) => ({
	type: PUT_EMPLOICAT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putEmploicatReturn = (result) => ({
	type: PUT_EMPLOICAT_RETURN,
	payload: result
});

//* POST PA CAT
export const postEmploicatSagas = (xAccess, data) => ({
	type: POST_EMPLOICAT_SAGAS,
	token: xAccess,
	data: data
});
export const postEmploicatReturn = (result) => ({
	type: POST_EMPLOICAT_RETURN,
	payload: result
});

//* DELETE PA CAT
export const deleteEmploicatSagas = (uuid, xAccess) => ({
	type: DEL_EMPLOICAT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteEmploicatReturn = (result) => ({
	type: DEL_EMPLOICAT_RETURN,
	payload: result
});
