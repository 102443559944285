import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetBlogs(action) {
	try {
		const result = yield fromApi.blogsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BLOGS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchGetBlog(action) {
	try {
		const result = yield fromApi.blogGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BLOG_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPostBlog(action) {
	try {
		const result = yield fromApi.blogsPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_BLOG_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPutBlog(action) {
	try {
		const result = yield fromApi.blogPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_BLOG_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchDelBlog(action) {
	try {
		const result = yield fromApi.blogDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_BLOG_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* blogs() {
	yield takeLatest('GET_BLOGS_SAGAS', watchGetBlogs);
	yield takeLatest('GET_BLOG_SAGAS', watchGetBlog);
	yield takeLatest('POST_BLOG_SAGAS', watchPostBlog);
	yield takeLatest('PUT_BLOG_SAGAS', watchPutBlog);
	yield takeLatest('DEL_BLOG_SAGAS', watchDelBlog);
}

export default function* rootSaga() {
	yield all([blogs()]);
}
