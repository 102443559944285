export const GET_CONCERTS_RETURN = 'GET_CONCERTS_RETURN';
export const GET_CONCERTS_SAGAS = 'GET_CONCERTS_SAGAS';

export const GET_CONCERT_RETURN = 'GET_CONCERT_RETURN';
export const GET_CONCERT_SAGAS = 'GET_CONCERT_SAGAS';

export const PUT_CONCERT_RETURN = 'PUT_CONCERT_RETURN';
export const PUT_CONCERT_SAGAS = 'PUT_CONCERT_SAGAS';

export const DEL_CONCERT_RETURN = 'DEL_CONCERT_RETURN';
export const DEL_CONCERT_SAGAS = 'DEL_CONCERT_SAGAS';

//* POST CONCERTS

//* GET ALL CONCERTS
export const getConcertsSagas = (page, range, cat, dep) => ({
	type: GET_CONCERTS_SAGAS,
	page,
	range,
	cat,
	dep
});
export const getConcertsReturn = (result) => ({
	type: GET_CONCERTS_RETURN,
	payload: result
});

//* GET ONE CONCERT
export const getConcertSagas = (uuid) => ({
	type: GET_CONCERT_SAGAS,
	payload: uuid
});
export const getConcertReturn = (result) => ({
	type: GET_CONCERT_RETURN,
	payload: result
});

//* UPDATE CONCERT
export const putConcertSagas = (uuid, data) => ({
	type: PUT_CONCERT_SAGAS,
	payload: uuid,
	data: data
});
export const putConcertReturn = (result) => ({
	type: PUT_CONCERT_RETURN,
	payload: result
});

//* DELETE CONCERTS
export const deleteConcertSagas = (uuid, xAccess) => ({
	type: DEL_CONCERT_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteConcertReturn = (result) => ({
	type: DEL_CONCERT_RETURN,
	payload: result
});
