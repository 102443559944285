import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: [],
	delete: []
};

const getMusiciensInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_MUSICIENS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'PUT_MUSICIEN_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.infos.data.items.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});

				let variable = {...state.infos.data, items: array};
				newStateUpdate = {...state.infos, data: variable};

				const notifySuccess = () =>
					toast.success('Musicien mis à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateUpdate = state.infos;
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdate
			});

		case 'DEL_MUSICIEN_SAGAS':
			const procat = state.infos.data.items;
			const indexcat = procat.findIndex((index) => index.uuid === action.payload);
			let newStateDelete = {...state.infos.data, items: procat.filter((item, i) => i !== indexcat)};
			newStateDelete = {...state.infos, data: newStateDelete};

			return Object.assign({}, state, {infos: newStateDelete});

		case 'DEL_MUSICIEN_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Musicien supprimé !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getMusiciensInfos;
