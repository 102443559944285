/*export const POST_PRO_RETURN = "POST_PRO_RETURN";
export const POST_PRO_SAGAS = "POST_PRO_SAGAS";*/

export const GET_ESPACES_RETURN = 'GET_ESPACES_RETURN';
export const GET_ESPACES_SAGAS = 'GET_ESPACES_SAGAS';

export const PUT_ESPACE_RETURN = 'PUT_ESPACE_RETURN';
export const PUT_ESPACE_SAGAS = 'PUT_ESPACE_SAGAS';

/*export const DEL_PRO_RETURN = "DEL_PRO_RETURN";
export const DEL_PRO_SAGAS = "DEL_PRO_SAGAS";*/

/********** PRO POST ****************/
/*export const postProSagas = (xAccess, data) =>({
    type: POST_PRO_SAGAS,
    token: xAccess,
    data: data
})
export const postProReturn = (result) =>({
    type: POST_PRO_RETURN,
    payload : result
})*/

/********** ESPACES GET ALL ****************/
export const getEspacesSagas = (xAccess, page, range, dep) => ({
	type: GET_ESPACES_SAGAS,
	token: xAccess,
	page,
	range,
	dep
});
export const getEspacesReturn = (result) => ({
	type: GET_ESPACES_RETURN,
	payload: result
});

/********** PRO GET ONE ****************/

/********** ESPACE PUT ****************/
export const putEspaceSagas = (uuid, xAccess, data) => ({
	type: PUT_ESPACE_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putEspaceReturn = (result) => ({
	type: PUT_ESPACE_RETURN,
	payload: result
});

/********** PRO CAT DELETE ****************/
/*export const deleteProSagas = (uuid, xAccess) =>({
    type: DEL_PRO_SAGAS,
    token: xAccess,
    payload: uuid
})
export const deleteProReturn = (result) =>({
    type: DEL_PRO_RETURN,
    payload : result
})*/
