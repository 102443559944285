import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostPro(action) {
	try {
		const result = yield fromApi.proPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_PRO_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetPros(action) {
	try {
		const result = yield fromApi.prosGet(action.token, action.page, action.range, action.cat, action.dep).then((data) => {
			return data;
		});
		yield put({type: 'GET_PROS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetProsExport(action) {
	try {
		const result = yield fromApi.prosExportGet(action.token, action.cat, action.dep).then((data) => {
			return data;
		});
		yield put({type: 'GET_PROS_EXPORT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutPro(action) {
	try {
		const result = yield fromApi.proPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_PRO_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelPro(action) {
	try {
		const result = yield fromApi.proDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_PRO_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* pros() {
	yield takeLatest('POST_PRO_SAGAS', watchPostPro);
	yield takeLatest('GET_PROS_SAGAS', watchGetPros);
	yield takeLatest('GET_PROS_EXPORT_SAGAS', watchGetProsExport);
	yield takeLatest('PUT_PRO_SAGAS', watchPutPro);
	yield takeLatest('DEL_PRO_SAGAS', watchDelPro);
}

export default function* rootSaga() {
	yield all([pros()]);
}
