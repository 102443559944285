import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostParrain(action) {
	try {
		const result = yield fromApi.parrainPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_PARRAIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetParrains(action) {
	try {
		const result = yield fromApi.parrainsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_PARRAINS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetParrain(action) {
	try {
		const result = yield fromApi.parrainGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_PARRAIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutParrain(action) {
	try {
		const result = yield fromApi.parrainPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_PARRAIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelParrain(action) {
	try {
		const result = yield fromApi.parrainDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_PARRAIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* parrains() {
	yield takeLatest('POST_PARRAIN_SAGAS', watchPostParrain);
	yield takeLatest('GET_PARRAINS_SAGAS', watchGetParrains);
	yield takeLatest('GET_PARRAIN_SAGAS', watchGetParrain);
	yield takeLatest('PUT_PARRAIN_SAGAS', watchPutParrain);
	yield takeLatest('DEL_PARRAIN_SAGAS', watchDelParrain);
}

export default function* rootSaga() {
	yield all([parrains()]);
}
