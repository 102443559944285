import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostTesteur(action) {
	try {
		const result = yield fromApi.bancstesteurPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_TESTEURS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTesteurs(action) {
	try {
		const result = yield fromApi.bancstesteursGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TESTEURS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTesteur(action) {
	try {
		const result = yield fromApi.bancstesteurGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TESTEUR_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutTesteur(action) {
	try {
		const result = yield fromApi.bancstesteurPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_TESTEUR_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelTesteur(action) {
	try {
		const result = yield fromApi.testeurDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_TESTEUR_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* bancstesteurs() {
	yield takeLatest('POST_TESTEURS_SAGAS', watchPostTesteur);
	yield takeLatest('GET_TESTEURS_SAGAS', watchGetTesteurs);
	yield takeLatest('GET_TESTEUR_SAGAS', watchGetTesteur);
	yield takeLatest('PUT_TESTEUR_SAGAS', watchPutTesteur);
	yield takeLatest('DEL_TESTEUR_SAGAS', watchDelTesteur);
}

export default function* rootSaga() {
	yield all([bancstesteurs()]);
}
