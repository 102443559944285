export const POST_NEWSLETTER_RETURN = 'POST_NEWSLETTER_RETURN';
export const POST_NEWSLETTER_SAGAS = 'POST_NEWSLETTER_SAGAS';

export const GET_NEWSLETTERS_RETURN = 'GET_NEWSLETTERS_RETURN';
export const GET_NEWSLETTERS_SAGAS = 'GET_NEWSLETTERS_SAGAS';

export const GET_NEWSLETTER_RETURN = 'GET_NEWSLETTER_RETURN';
export const GET_NEWSLETTER_SAGAS = 'GET_NEWSLETTER_SAGAS';

export const PUT_NEWSLETTER_RETURN = 'PUT_NEWSLETTER_RETURN';
export const PUT_NEWSLETTER_SAGAS = 'PUT_NEWSLETTER_SAGAS';

export const POST_NEWSEXPE_RETURN = 'POST_NEWSEXPE_RETURN';
export const POST_NEWSEXPE_SAGAS = 'POST_NEWSEXPE_SAGAS';

export const POST_NEWSEXPEGROUP_RETURN = 'POST_NEWSEXPEGROUP_RETURN';
export const POST_NEWSEXPEGROUP_SAGAS = 'POST_NEWSEXPEGROUP_SAGAS';

export const DEL_NEWSLETTER_RETURN = 'DEL_NEWSLETTER_RETURN';
export const DEL_NEWSLETTER_SAGAS = 'DEL_NEWSLETTER_SAGAS';

// POST NEWSLETTER
export const postNewsletterSagas = (xAccess, data) => ({
	type: POST_NEWSLETTER_SAGAS,
	token: xAccess,
	data: data
});
export const postNewsletterReturn = (result) => ({
	type: POST_NEWSLETTER_RETURN,
	payload: result
});

// GET ALL NEWSLETTERS
export const getNewslettersSagas = (xAccess) => ({
	type: GET_NEWSLETTERS_SAGAS,
	token: xAccess
});
export const getNewslettersReturn = (result) => ({
	type: GET_NEWSLETTERS_RETURN,
	payload: result
});

//* GET ONE NEWSLETTERS
export const getNewsletterSagas = (uuid, xAccess) => ({
	type: GET_NEWSLETTER_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getNewsletterReturn = (result) => ({
	type: GET_NEWSLETTER_RETURN,
	payload: result
});

//* PUT NEWSLETTER
export const putNewsletterSagas = (uuid, xAccess, data) => ({
	type: PUT_NEWSLETTER_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putNewsletterReturn = (result) => ({
	type: PUT_NEWSLETTER_RETURN,
	payload: result
});

//* POST NEWSLETTER EXPEDITION
export const postNewsExpeSagas = (uuid, xAccess, data) => ({
	type: POST_NEWSEXPE_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const postNewsExpeReturn = (result) => ({
	type: POST_NEWSEXPE_RETURN,
	payload: result
});

//* POST NEWSLETTER EXPEDITION GROUPE
export const postNewsExpeGroupSagas = (uuid, xAccess, data) => ({
	type: POST_NEWSEXPEGROUP_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const postNewsExpeGroupReturn = (result) => ({
	type: POST_NEWSEXPEGROUP_RETURN,
	payload: result
});

//* DELETE NEWSLETTER
export const deleteNewsletterSagas = (uuid, xAccess) => ({
	type: DEL_NEWSLETTER_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteNewsletterReturn = (result) => ({
	type: DEL_NEWSLETTER_RETURN,
	payload: result
});
