import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	one: [],
	create: [],
	update: []
};

const getDepartementsInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_DEPARTEMENTS_RETURN':
			return Object.assign({}, state, {infos: action.payload});

		case 'POST_DEPARTEMENT_RETURN':
			const arrayData = [action.payload.data];
			let newStateAdd;
			if (action.payload.status === 201) {
				newStateAdd = {...state.infos, data: state.infos.data.concat(arrayData)};
				const notifySuccess = () =>
					toast.success('Département ajoutée !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateAdd = state.infos;
				if (action.payload.status === 400) {
					const notifyError = () =>
						toast.error('Erreur ! merci de le signaler...', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						});
					notifyError();
				}
			}
			return Object.assign({}, state, {
				create: action.payload,
				infos: newStateAdd
			});

		case 'PUT_DEPARTEMENT_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.infos.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateUpdate = {...state.infos, data: array};

				const notifySuccess = () =>
					toast.success('Département mis à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				newStateUpdate = state.infos;
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				infos: newStateUpdate
			});

		case 'DEL_DEPARTEMENT_SAGAS':
			const dep = state.infos.data;
			const indexDep = dep.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.infos, data: dep.filter((item, i) => i !== indexDep)};

			return Object.assign({}, state, {infos: newStateDelete});

		case 'DEL_DEPARTEMENT_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Département supprimé !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getDepartementsInfos;
