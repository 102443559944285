import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostDepartement(action) {
	try {
		const result = yield fromApi.departementPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_DEPARTEMENT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGeDepartements(action) {
	try {
		const result = yield fromApi.departementsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_DEPARTEMENTS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutDepartement(action) {
	try {
		const result = yield fromApi.departementPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_DEPARTEMENT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelDepartement(action) {
	try {
		const result = yield fromApi.departementDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_DEPARTEMENT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* departements() {
	yield takeLatest('POST_DEPARTEMENT_SAGAS', watchPostDepartement);
	yield takeLatest('GET_DEPARTEMENTS_SAGAS', watchGeDepartements);
	yield takeLatest('PUT_DEPARTEMENT_SAGAS', watchPutDepartement);
	yield takeLatest('DEL_DEPARTEMENT_SAGAS', watchDelDepartement);
}

export default function* rootSaga() {
	yield all([departements()]);
}
