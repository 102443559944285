import {combineReducers} from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import login from './auth/login.reducer';
import authUser from './auth/user.reducer';
import admins from './admins/admins.reducer';
import users from './users/users.reducer';
import blogs from './blog/blog.reducer';
import blogcats from './blog/blogcat.reducer';
import tremplins from './tremplins/tremplins.reducers';
import tremplinscandidat from './tremplins/tremplinscandidats.reducers';
import tremplinslaureat from './tremplins/tremplinslaureats.reducers';
import tremplinscat from './tremplinscat/tremplinscat.reducer';
import parrains from './parrains/parrains.reducer';
import bancs from './bancs/bancs.reducer';
import bancscat from './bancs/bancscat.reducer';
import bancstesteurs from './bancs/bancstesteurs.reducer';
import bancsrevendeurs from './bancs/revendeurs.reducer';
import marques from './marques/marques.reducer';
import departements from './departements/departements.reducer';
import pros from './annuairepro/pro.reducer';
import procats from './annuairepro/procats.reducer';
import musiciens from './annuairemusiciens/musiciens.reducer';
import musicienscats from './annuairemusiciens/musicienscats.reducer';
import pleinfeux from './accueil/pleinfeux.reducer';
import coupdecoeur from './accueil/coupdecoeur.reducer';
import messageHome from './accueil/message.reducer';
import annonces from './annonces/annonces.reducers';
import annoncesVendre from './annonces/vendre.reducer';
import annoncescat from './annonces/annoncescat.reducers';
import emplois from './emplois/emplois.reducer';
import emploiscat from './emplois/emploiscat.reducers';
import datasusers from './datas/datas.reducer';
import tickets from './tickets/tickets.reducer';
import ticketstypes from './tickets/ticketstypes.reducer';
import concerts from './concerts/concerts.reducer';
import concertscat from './concerts/concertscat.reducer';
import espaces from './espaces/espaces.reducer';
import espaceimg from './espaces/espaceimg.reducer';
import newsletters from './newsletters/newsletters.reducer';

const rootReducer = combineReducers({
	customizer: customizer,
	auth: auth,
	login: login,
	authUser: authUser,
	admins: admins,
	users: users,
	blogs: blogs,
	blogcats: blogcats,
	tremplins: tremplins,
	tremplinscandidat: tremplinscandidat,
	tremplinslaureat: tremplinslaureat,
	tremplinscat: tremplinscat,
	parrains: parrains,
	bancscat: bancscat,
	bancstesteurs: bancstesteurs,
	bancsrevendeurs: bancsrevendeurs,
	bancs: bancs,
	marques: marques,
	departements: departements,
	pros: pros,
	procats: procats,
	musiciens: musiciens,
	musicienscats: musicienscats,
	coupdecoeur: coupdecoeur,
	pleinfeux: pleinfeux,
	messageHome: messageHome,
	annonces: annonces,
	annoncesVendre: annoncesVendre,
	annoncescat: annoncescat,
	emplois: emplois,
	emploiscat: emploiscat,
	datasusers: datasusers,
	tickets: tickets,
	ticketstypes: ticketstypes,
	concerts: concerts,
	concertscat: concertscat,
	espaces: espaces,
	espaceimg: espaceimg,
	newsletters: newsletters
});

export default rootReducer;
