let initialState = {
	infos: []
};

const authUserInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'AUTH_USER_RETURN':
			return Object.assign({}, state, {infos: action.payload});
		default:
			return state;
	}
};

export default authUserInfos;
