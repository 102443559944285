import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetAdmins(action) {
	try {
		const result = yield fromApi.adminsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_ADMINS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchGetAdmin(action) {
	try {
		const result = yield fromApi.adminGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_ADMIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPutAdmin(action) {
	try {
		const result = yield fromApi.adminPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_ADMIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchPostAdmin(action) {
	try {
		const result = yield fromApi.adminPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_ADMIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* watchDelAdmin(action) {
	try {
		const result = yield fromApi.adminDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_ADMIN_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* admins() {
	yield takeLatest('GET_ADMINS_SAGAS', watchGetAdmins);
	yield takeLatest('GET_ADMIN_SAGAS', watchGetAdmin);
	yield takeLatest('PUT_ADMIN_SAGAS', watchPutAdmin);
	yield takeLatest('POST_ADMIN_SAGAS', watchPostAdmin);
	yield takeLatest('DEL_ADMIN_SAGAS', watchDelAdmin);
}

export default function* rootSaga() {
	yield all([admins()]);
}
