import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostEspaceImg(action) {
	try {
		const result = yield fromApi.espaceImgPost(action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_ESIMG_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* espaceimg() {
	yield takeLatest('POST_ESIMG_SAGAS', watchPostEspaceImg);
}

export default function* rootSaga() {
	yield all([espaceimg()]);
}
