export const GET_MUSICIENS_RETURN = 'GET_MUSICIENS_RETURN';
export const GET_MUSICIENS_SAGAS = 'GET_MUSICIENS_SAGAS';

export const PUT_MUSICIEN_RETURN = 'PUT_MUSICIEN_RETURN';
export const PUT_MUSICIEN_SAGAS = 'PUT_MUSICIEN_SAGAS';

export const DEL_MUSICIEN_RETURN = 'DEL_MUSICIEN_RETURN';
export const DEL_MUSICIEN_SAGAS = 'DEL_MUSICIEN_SAGAS';

/********** MUSICIENS POST ****************/
/*export const postMusiciencatSagas = (xAccess, data) =>({
    type: POST_MUSICIENCAT_SAGAS,
    token: xAccess,
    data: data
})
export const postMusiciencatReturn = (result) =>({
    type: POST_MUSICIENCAT_RETURN,
    payload : result
})*/

/********** MUSICIENS GET ALL ****************/
export const getMusiciensSagas = (xAccess, page, range, cat, dep) => ({
	type: GET_MUSICIENS_SAGAS,
	token: xAccess,
	page,
	range,
	cat,
	dep
});
export const getMusiciensReturn = (result) => ({
	type: GET_MUSICIENS_RETURN,
	payload: result
});

/********** MUSICIENS GET ONE ****************/

/********** MUSICIENS PUT ****************/
export const putMusicienSagas = (uuid, xAccess, data) => ({
	type: PUT_MUSICIEN_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putMusicienReturn = (result) => ({
	type: PUT_MUSICIEN_RETURN,
	payload: result
});

/********** MUSICIENS DELETE ****************/
export const deleteMusicienSagas = (uuid, xAccess) => ({
	type: DEL_MUSICIEN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteMusicienReturn = (result) => ({
	type: DEL_MUSICIEN_RETURN,
	payload: result
});
