import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetTremplinscat(action) {
	try {
		const result = yield fromApi.tremplinscatGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TREMPLINSCATS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetTremplincat(action) {
	try {
		const result = yield fromApi.tremplincatGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TREMPLINCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetCatTremplins(action) {
	try {
		const result = yield fromApi.tremplincatGetTremplin(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_CAT_TREMPLINS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPostTremplinscat(action) {
	try {
		const result = yield fromApi.tremplinscatPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_TREMPLINSCATS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutTremplincat(action) {
	try {
		const result = yield fromApi.tremplincatPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_TREMPLINCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutTremplincatOrdre(action) {
	try {
		const result = yield fromApi.tremplincatOrdrePut(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_TREMPLINCATORDRE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelTremplincat(action) {
	try {
		const result = yield fromApi.tremplincatDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_TREMPLINCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* tremplinsCat() {
	yield takeLatest('GET_TREMPLINSCATS_SAGAS', watchGetTremplinscat);
	yield takeLatest('POST_TREMPLINSCATS_SAGAS', watchPostTremplinscat);
	yield takeLatest('GET_TREMPLINCAT_SAGAS', watchGetTremplincat);
	yield takeLatest('GET_CAT_TREMPLINS_SAGAS', watchGetCatTremplins);
	yield takeLatest('PUT_TREMPLINCAT_SAGAS', watchPutTremplincat);
	yield takeLatest('PUT_TREMPLINCATORDRE_SAGAS', watchPutTremplincatOrdre);
	yield takeLatest('DEL_TREMPLINCAT_SAGAS', watchDelTremplincat);
}

export default function* rootSaga() {
	yield all([tremplinsCat()]);
}
