export const POST_PRO_RETURN = 'POST_PRO_RETURN';
export const POST_PRO_SAGAS = 'POST_PRO_SAGAS';

export const GET_PROS_RETURN = 'GET_PROS_RETURN';
export const GET_PROS_SAGAS = 'GET_PROS_SAGAS';

export const GET_PROS_EXPORT_RETURN = 'GET_PROS_EXPORT_RETURN';
export const GET_PROS_EXPORT_SAGAS = 'GET_PROS_EXPORT_SAGAS';

export const PUT_PRO_RETURN = 'PUT_PRO_RETURN';
export const PUT_PRO_SAGAS = 'PUT_PRO_SAGAS';

export const DEL_PRO_RETURN = 'DEL_PRO_RETURN';
export const DEL_PRO_SAGAS = 'DEL_PRO_SAGAS';

/********** PRO POST ****************/
export const postProSagas = (xAccess, data) => ({
	type: POST_PRO_SAGAS,
	token: xAccess,
	data: data
});
export const postProReturn = (result) => ({
	type: POST_PRO_RETURN,
	payload: result
});

/********** PRO GET ALL ****************/
export const getProsSagas = (xAccess, page, range, cat, dep) => ({
	type: GET_PROS_SAGAS,
	token: xAccess,
	page,
	range,
	cat,
	dep
});
export const getProsReturn = (result) => ({
	type: GET_PROS_RETURN,
	payload: result
});

/********** PRO GET EXPORT ****************/
export const getProsExportSagas = (xAccess, cat, dep) => ({
	type: GET_PROS_EXPORT_SAGAS,
	token: xAccess,
	cat,
	dep
});
export const getProsExportReturn = (result) => ({
	type: GET_PROS_EXPORT_RETURN,
	payload: result
});

/********** PRO GET ONE ****************/

/********** PRO PUT ****************/
export const putProSagas = (uuid, xAccess, data) => ({
	type: PUT_PRO_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putProReturn = (result) => ({
	type: PUT_PRO_RETURN,
	payload: result
});

/********** PRO CAT DELETE ****************/
export const deleteProSagas = (uuid, xAccess) => ({
	type: DEL_PRO_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteProReturn = (result) => ({
	type: DEL_PRO_RETURN,
	payload: result
});
