export const LOGIN_USER_RETURN = 'LOGIN_USER_RETURN';
export const LOGIN_USER_SAGAS = 'LOGIN_USER_SAGAS';

export const loginUserSagas = (loginUserForm) => ({
	type: LOGIN_USER_SAGAS,
	payload: loginUserForm
});

export const loginUserReturn = (result) => ({
	type: LOGIN_USER_RETURN,
	payload: result
});
