export const GET_PLEINS_RETURN = 'GET_PLEINS_RETURN';
export const GET_PLEINS_SAGAS = 'GET_PLEINS_SAGAS';

export const POST_PLEIN_RETURN = 'POST_PLEIN_RETURN';
export const POST_PLEIN_SAGAS = 'POST_PLEIN_SAGAS';

export const GET_PLEIN_RETURN = 'GET_PLEIN_RETURN';
export const GET_PLEIN_SAGAS = 'GET_PLEIN_SAGAS';

export const PUT_PLEIN_RETURN = 'PUT_PLEIN_RETURN';
export const PUT_PLEIN_SAGAS = 'PUT_PLEIN_SAGAS';

export const PUT_PLEINSTATUT_RETURN = 'PUT_PLEINSTATUT_RETURN';
export const PUT_PLEINSTATUT_SAGAS = 'PUT_PLEINSTATUT_SAGAS';

export const DEL_PLEIN_RETURN = 'DEL_PLEIN_RETURN';
export const DEL_PLEIN_SAGAS = 'DEL_PLEIN_SAGAS';

//* GET PLEIN FEUX ALL
export const getPleinsSagas = (xAccess) => ({
	type: GET_PLEINS_SAGAS,
	token: xAccess
});
export const getPleinsReturn = (result) => ({
	type: GET_PLEINS_RETURN,
	payload: result
});

//* POST PLEIN FEUX
export const postPleinSagas = (xAccess, data) => ({
	type: POST_PLEIN_SAGAS,
	token: xAccess,
	data
});
export const postPleinReturn = (result) => ({
	type: POST_PLEIN_RETURN,
	payload: result
});

//* GET PLEIN FEUX ONE
export const getPleinSagas = (uuid, xAccess) => ({
	type: GET_PLEIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getPleinReturn = (result) => ({
	type: GET_PLEIN_RETURN,
	payload: result
});

//* PUT PLEIN FEUX
export const putPleinSagas = (uuid, xAccess, data) => ({
	type: PUT_PLEIN_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putPleinReturn = (result) => ({
	type: PUT_PLEIN_RETURN,
	payload: result
});

//* PUT PLEIN FEUX STATUT
export const putPleinStatutSagas = (uuid, xAccess, data) => ({
	type: PUT_PLEINSTATUT_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putPleinStatutReturn = (result) => ({
	type: PUT_PLEINSTATUT_RETURN,
	payload: result
});

//* DELETE PLEIN FEUX
export const deletePleinSagas = (uuid, xAccess) => ({
	type: DEL_PLEIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deletePleinReturn = (result) => ({
	type: DEL_PLEIN_RETURN,
	payload: result
});
