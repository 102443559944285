import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetTicketstypes(action) {
	try {
		const result = yield fromApi.ticketstypesGets(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_TYPES_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPostTicketstypes(action) {
	try {
		const result = yield fromApi.ticketstypesPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_TYPE_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutTicketstypes(action) {
	try {
		const result = yield fromApi.ticketstypesPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_TYPES_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelTicketstypes(action) {
	try {
		const result = yield fromApi.ticketstypesDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_TYPES_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* ticketstypes() {
	yield takeLatest('GET_TYPES_SAGAS', watchGetTicketstypes);
	yield takeLatest('POST_TYPE_SAGAS', watchPostTicketstypes);
	yield takeLatest('PUT_TYPES_SAGAS', watchPutTicketstypes);
	yield takeLatest('DEL_TYPES_SAGAS', watchDelTicketstypes);
}

export default function* rootSaga() {
	yield all([ticketstypes()]);
}
