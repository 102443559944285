import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchPostBancscat(action) {
	try {
		const result = yield fromApi.bancscatPost(action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'POST_BANCSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetBancscats(action) {
	try {
		const result = yield fromApi.bancscatsGet(action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BANCSCATS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetBancscat(action) {
	try {
		const result = yield fromApi.bancscatGet(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'GET_BANCSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutBancscat(action) {
	try {
		const result = yield fromApi.bancscatPut(action.payload, action.token, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_BANCSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelBancscat(action) {
	try {
		const result = yield fromApi.bancscatDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_BANCSCAT_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* bancscats() {
	yield takeLatest('POST_BANCSCAT_SAGAS', watchPostBancscat);
	yield takeLatest('GET_BANCSCATS_SAGAS', watchGetBancscats);
	yield takeLatest('GET_BANCSCAT_SAGAS', watchGetBancscat);
	yield takeLatest('PUT_BANCSCAT_SAGAS', watchPutBancscat);
	yield takeLatest('DEL_BANCSCAT_SAGAS', watchDelBancscat);
}

export default function* rootSaga() {
	yield all([bancscats()]);
}
