export const POST_TREMPLIN_RETURN = 'POST_TREMPLIN_RETURN';
export const POST_TREMPLIN_SAGAS = 'POST_TREMPLIN_SAGAS';

export const GET_TREMPLINS_RETURN = 'GET_TREMPLINS_RETURN';
export const GET_TREMPLINS_SAGAS = 'GET_TREMPLINS_SAGAS';

export const GET_TREMPLIN_RETURN = 'GET_TREMPLIN_RETURN';
export const GET_TREMPLIN_SAGAS = 'GET_TREMPLIN_SAGAS';

export const PUT_TREMPLIN_RETURN = 'PUT_TREMPLIN_RETURN';
export const PUT_TREMPLIN_SAGAS = 'PUT_TREMPLIN_SAGAS';

export const CLOTURE_TREMPLIN_RETURN = 'CLOTURE_TREMPLIN_RETURN';
export const CLOTURE_TREMPLIN_SAGAS = 'CLOTURE_TREMPLIN_SAGAS';

export const DEL_TREMPLIN_RETURN = 'DEL_TREMPLIN_RETURN';
export const DEL_TREMPLIN_SAGAS = 'DEL_TREMPLIN_SAGAS';

//* POST TREMPLIN
export const postTremplinSagas = (xAccess, data) => ({
	type: POST_TREMPLIN_SAGAS,
	token: xAccess,
	data: data
});
export const postTremplinReturn = (result) => ({
	type: POST_TREMPLIN_RETURN,
	payload: result
});

//* GET TREMPLINS ALL
export const getTremplinsSagas = (xAccess) => ({
	type: GET_TREMPLINS_SAGAS,
	token: xAccess
});
export const getTremplinsReturn = (result) => ({
	type: GET_TREMPLINS_RETURN,
	payload: result
});

//* GET ONE TREMPLIN
export const getTremplinSagas = (uuid, xAccess) => ({
	type: GET_TREMPLIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getTremplintReturn = (result) => ({
	type: GET_TREMPLIN_RETURN,
	payload: result
});

//* PUT TREMPLIN
export const putTremplinSagas = (uuid, xAccess, data) => ({
	type: PUT_TREMPLIN_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const putTremplinReturn = (result) => ({
	type: PUT_TREMPLIN_RETURN,
	payload: result
});

//* CLOTURE TREMPLIN
export const clotureTremplinSagas = (uuid, xAccess, data) => ({
	type: CLOTURE_TREMPLIN_SAGAS,
	token: xAccess,
	payload: uuid,
	data: data
});
export const clotureTremplinReturn = (result) => ({
	type: CLOTURE_TREMPLIN_RETURN,
	payload: result
});

//* DELETE TREMPLIN
export const deleteTremplinSagas = (uuid, xAccess) => ({
	type: DEL_TREMPLIN_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteTremplinReturn = (result) => ({
	type: DEL_TREMPLIN_RETURN,
	payload: result
});
