import React, {useEffect} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Axios from 'axios';
import {Configs} from '../../configs/general.config';
import jwt_decode from 'jwt-decode';

function PrivateRoute({render, ...rest}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const {singIn} = useSelector(
		(state) => ({
			singIn: state.login
		}),
		shallowEqual
	);

	let {login} = {...singIn};

	const checkToken = async (data) => {
		let results = false;
		try {
			await Axios({
				method: 'post',
				url: `${Configs.urlApi}/api/${Configs.typeApi}/v1/authenticator/check-token`,
				data: {token: data},
				headers: {
					Authorization: `Bearer ${Configs.BearerToken}`
				}
			})
				.then(() => {
					results = true;
				})
				.catch(() => {
					localStorage.clear();
					dispatch({
						type: 'LOGIN_USER_RETURN',
						payload: []
					});
				});
		} catch (error) {
			localStorage.clear();
			dispatch({
				type: 'LOGIN_USER_RETURN',
				payload: []
			});
		}

		return results;
	};

	useEffect(() => {
		if (Object.keys(login).length !== 0) {
			checkToken(login.data.token);
		}
	});

	const isAuth = () => {
		if (Object.keys(login).length !== 0) {
			return true;
		} else {
			return false;
		}
	};

	const is_su = () => {
		if (isAuth && login.data) {
			const userInfo = jwt_decode(login.data.token);
			if (userInfo.role === 3) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (!isAuth()) {
			history.push({pathname: '/login'});
		} else {
			if (!is_su()) {
				history.push({pathname: '/', data: true}); // a changer par la route unauthorized
			}
		}
	});

	return <Route {...rest} render={isAuth() && is_su() ? render : null} />;
}

export default PrivateRoute;
