import React, {useEffect} from 'react';
import {Navbar} from 'reactstrap';
import classnames from 'classnames';
import NavbarUser from './NavbarUser';
import {connect} from 'react-redux';
import * as fromActions from '../../../redux/actions';

const ThemeNavbar = (props) => {
	const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
	const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
	const first_name = props.user ? props.user.first_name : '';
	const last_name = props.user ? props.user.last_name : '';
	const {token, authUser} = props;
	useEffect(() => {
		if (token) {
			authUser(token.uuid, token.token);
		}
	}, [token, authUser]);

	return (
		<React.Fragment>
			<div className="content-overlay" />
			<div className="header-navbar-shadow" />
			<Navbar
				className={classnames('header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow', {
					'navbar-light': props.navbarColor === 'default' || !colorsArr.includes(props.navbarColor),
					'navbar-dark': colorsArr.includes(props.navbarColor),
					'bg-primary': props.navbarColor === 'primary' && props.navbarType !== 'static',
					'bg-danger': props.navbarColor === 'danger' && props.navbarType !== 'static',
					'bg-success': props.navbarColor === 'success' && props.navbarType !== 'static',
					'bg-info': props.navbarColor === 'info' && props.navbarType !== 'static',
					'bg-warning': props.navbarColor === 'warning' && props.navbarType !== 'static',
					'bg-dark': props.navbarColor === 'dark' && props.navbarType !== 'static',
					'd-none': props.navbarType === 'hidden' && !props.horizontal,
					'floating-nav': (props.navbarType === 'floating' && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
					'navbar-static-top': props.navbarType === 'static' && !props.horizontal,
					'fixed-top': props.navbarType === 'sticky' || props.horizontal,
					scrolling: props.horizontal && props.scrolling
				})}>
				<div className="navbar-wrapper">
					<div className="navbar-container content">
						<div className="navbar-collapse d-flex justify-content-between align-items-center" id="navbar-mobile">
							<div className="bookmark-wrapper">
							</div>
							{props.horizontal ? (
								<div className="logo d-flex align-items-center">
									<div className="brand-logo mr-50"></div>
									<h2 className="text-primary brand-text mb-0">Musiconline</h2>
								</div>
							) : null}
							<NavbarUser
								handleAppOverlay={props.handleAppOverlay}
								changeCurrentLang={props.changeCurrentLang}
								userName={first_name + ' ' + last_name}
								firstName={first_name}
								lastName={last_name}
							/>
						</div>
					</div>
				</div>
			</Navbar>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	token: state.login.login?.data,
	user: state.authUser.infos?.data
});

const mapDispatchToProps = (dispatch) => ({
	authUser: (x, y) => dispatch(fromActions.authUserSagas(x, y))
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeNavbar);
