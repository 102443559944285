import {toast} from 'react-toastify';

let initialState = {
	infos: [],
	attente: [],
	one: [],
	create: [],
	update: []
};

const getTremplinsCandInfos = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_CANDATTENTE_RETURN':
			return Object.assign({}, state, {attente: action.payload});

		case 'GET_CANDIDAT_RETURN':
			return Object.assign({}, state, {one: action.payload});

		case 'PUT_CANDIDAT_RETURN':
			let array = [];
			let newStateUpdate;
			if (action.payload.status === 202) {
				array = state.attente.data.map((item) => {
					return item.uuid === action.payload.data.uuid ? action.payload.data : item;
				});
				newStateUpdate = {...state.attente, data: array};
				const notifySuccess = () =>
					toast.success('Candidature mise à jour !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {
				update: action.payload,
				attente: newStateUpdate
			});

		case 'DEL_CANDIDAT_SAGAS':
			const candidat = state.attente.data;
			const indexCandidat = candidat.findIndex((index) => index.uuid === action.payload);
			const newStateDelete = {...state.attente, data: candidat.filter((item, i) => i !== indexCandidat)};

			return Object.assign({}, state, {attente: newStateDelete});
		case 'DEL_CANDIDAT_RETURN':
			if (action.payload.status === 202) {
				const notifySuccess = () =>
					toast.success('Candidat supprimé !', {
						position: 'top-right',
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifySuccess();
			} else {
				const notifyError = () =>
					toast.error('Erreur ! merci de le signaler...', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				notifyError();
			}
			return Object.assign({}, state, {delete: action.payload});

		default:
			return state;
	}
};

export default getTremplinsCandInfos;
