export const POST_REPONSE_RETURN = 'POST_REPONSE_RETURN';
export const POST_REPONSE_SAGAS = 'POST_REPONSE_SAGAS';

export const GET_TICKETS_RETURN = 'GET_TICKETS_RETURN';
export const GET_TICKETS_SAGAS = 'GET_TICKETS_SAGAS';

export const GET_TICKETREPONSE_RETURN = 'GET_TICKETREPONSE_RETURN';
export const GET_TICKETREPONSE_SAGAS = 'GET_TICKETREPONSE_SAGAS';

export const DEL_TICKET_RETURN = 'DEL_TICKET_RETURN';
export const DEL_TICKET_SAGAS = 'DEL_TICKET_SAGAS';

//* POST REPONSE TICKET
export const postReponseSagas = (xAccess, data) => ({
	type: POST_REPONSE_SAGAS,
	token: xAccess,
	data: data
});
export const postReponseReturn = (result) => ({
	type: POST_REPONSE_RETURN,
	payload: result
});

//* GET ALL TICKETS
export const getTicketsSagas = (xAccess) => ({
	type: GET_TICKETS_SAGAS,
	token: xAccess
});
export const getTicketsReturn = (result) => ({
	type: GET_TICKETS_RETURN,
	payload: result
});

//* GET ONE TICKET BY REPONSE
export const getTicketReponseSagas = (uuid, xAccess) => ({
	type: GET_TICKETREPONSE_SAGAS,
	token: xAccess,
	payload: uuid
});
export const getTicketReponseReturn = (result) => ({
	type: GET_TICKETREPONSE_RETURN,
	payload: result
});

//* PUT TICKET

//* DELETE TICKET
export const deleteTicketSagas = (uuid, xAccess) => ({
	type: DEL_TICKET_SAGAS,
	token: xAccess,
	payload: uuid
});
export const deleteTicketReturn = (result) => ({
	type: DEL_TICKET_RETURN,
	payload: result
});
