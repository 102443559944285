import {takeLatest, put, all} from 'redux-saga/effects';
import * as fromApi from '../../../lib/api/musiconline.api';

function* watchGetEmplois(action) {
	try {
		const result = yield fromApi.emploisGet(action.page, action.range, action.cat, action.dep).then((data) => {
			return data;
		});
		yield put({type: 'GET_EMPLOIS_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchGetEmploi(action) {
	try {
		const result = yield fromApi.emploiGet(action.payload).then((data) => {
			return data;
		});
		yield put({type: 'GET_EMPLOI_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchPutEmploi(action) {
	try {
		const result = yield fromApi.emploiPut(action.payload, action.data).then((data) => {
			return data;
		});
		yield put({type: 'PUT_EMPLOI_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}
function* watchDelEmploi(action) {
	try {
		const result = yield fromApi.paDel(action.payload, action.token).then((data) => {
			return data;
		});
		yield put({type: 'DEL_PA_RETURN', payload: result});
	} catch (error) {
		yield put({
			type: 'ERROR',
			error: true,
			payload: error
		});
	}
}

function* emplois() {
	yield takeLatest('GET_EMPLOIS_SAGAS', watchGetEmplois);
	yield takeLatest('GET_EMPLOI_SAGAS', watchGetEmploi);
	yield takeLatest('PUT_EMPLOI_SAGAS', watchPutEmploi);
	yield takeLatest('DEL_EMPLOI_SAGAS', watchDelEmploi);
}

export default function* rootSaga() {
	yield all([emplois()]);
}
